/**
 * Compare list
 */
 export const LIST_COMPARE = [
  {
    name: "URL(テキスト)",
    row: 1,
  },

  {
    name: "サイト評価",
    row: 2,
  },

  {
    name: "競合サイト評価",
    row: 2,
  },
];

/**
 * Question list
 */
export const LIST_QUESTION = [
  {
    id: 1,
    name: "コンテンツ対策数",
    order: 1,
    answer: [
      {
        name: "1コンテンツ",
        value: 1,
      },
      {
        name: "2コンテンツ",
        value: 2,
      },
      {
        name: "5コンテンツ",
        value: 5,
      },
      {
        name: "10コンテンツ",
        value: 10,
      },
    ],
    type: 1,
  },

  {
    id: 2,
    name: "キーワード対策数",
    order: 2,
    answer: [
      {
        name: "3KW",
        value: 3,
      },
      {
        name: "5KW",
        value: 5,
      },
      {
        name: "15KW",
        value: 15,
      },
      {
        name: "30KW",
        value: 30,
      },
      {
        name: "50KW",
        value: 50,
      },
    ],
    type: 1,
  },

  {
    id: 3,
    name: "サイトスコア（獲得したい評価）",
    order: 4,
    type: 0,
  },

  {
    id: 4,
    name: "D 集客エリア数",
    order: 5,
    answer: [
      {
        name: "S1エリア （2000/月まで）",
        value: 0,
      },
      {
        name: "~S3エリア （5000/月まで）",
        value: 12000,
      },
      {
        name: "A1エリア（7000/月まで）",
        value: 13000,
      },
      {
        name: "A2エリア（10000/月まで）",
        value: 14000,
      },
      {
        name: "A3エリア（20000/月まで）",
        value: 15000,
      },
      {
        name: "全国エリア",
        value: 16000,
      },
      {
        name: "海外",
        value: 17000,
      },
    ],
    type: 1,
  },

  {
    id: 5,
    name: "E 獲得集客数（CRV）",
    order: 6,
    answer: [
      {
        name: "~10",
        value: 200000,
      },
      {
        name: "~30",
        value: 350000,
      },
      {
        name: "~50",
        value: 460000,
      },
      {
        name: "~100",
        value: 870000,
      },
      {
        name: "~200",
        value: 1380000,
      },
    ],
    type: 1,
  },

  {
    id: 6,
    name: "ローカルSEO",
    order: 3,
    answer: [
      {
        name: "5KW",
        value: 270000,
      },
      {
        name: "10KW",
        value: 320000,
      },
      {
        name: "15KW",
        value: 400000,
      },
    ],
    type: 1,
  },
];

/**
 *  list UNIT_PRICE
 */
export const UNIT_PRICE = [
  {
    questionId: 1,
    price: 45000,
    calculationType: 1,
  },
  {
    questionId: 2,
    calculationType: 3,
  },
  {
    questionId: 3,
    price: 18600,
    calculationType: 1,
  },
  {
    questionId: 4,
    calculationType: 2,
  },
  {
    questionId: 5,
    calculationType: 3,
  },
  {
    questionId: 6,
    calculationType: 3,
  },
];

/**
 *  Price option
 */
export const PRICE_OPTIONS = [
  {
    id: 0,
    name: "診断フォーム",
    price: 98000,
  },
  {
    id: 1,
    name: "オンライン予約システム",
    price: 98000,
  },
  {
    id: 2,
    name: "オンラインカード決済システム",
    price: 98000,
  },
  {
    id: 3,
    name: "サイト修正＆デザインアップ",
    price: 98000,
  },
  {
    id: 4,
    name: "動画オプション",
    price: 98000,
  },
  {
    id: 5,
    name: "指示書・レポート作成",
    price: 100000,
  },
];
