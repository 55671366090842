<template>
  <div class="text-center container">
    <div
      class="box-homepage d-flex justify-content-between flex-column bg-white text-black text-left h-100 mx-auto mt-5"
    >
      <h3 class="m-0 mb-4 fw-bold text-center">シミュレーション結果</h3>
      <p class="text-center fw-bold m-0">
        <label class="w-75">
          <div class="d-flex fs-5 justify-content-between">
            <span> 小計: </span>
            <span class="text-success">
              {{ total | formatPriceJP }}
              <span class="text-dark">円</span>
            </span>
          </div>
          <div class="d-flex fs-5 justify-content-between">
            <span> 消費税(10%): </span>
            <span class="text-success">
              {{ ((total * 10) / 100) | formatPriceJP }}
              <span class="text-dark">円</span>
            </span>
          </div>
          <div
            class="d-flex fs-3 fs-sm-2 justify-content-between border-top border-info border-2 mt-3"
          >
            <span> 制作費用 合計: </span>
            <span class="text-primary fw-bold">
              {{ ((total * 10) / 100 + total) | formatPriceJP }}
              <span class="text-dark">円</span>
            </span>
          </div>
          <div class="d-flex fs-5 justify-content-between">
            <span> 月々の支払: </span>
            <span class="text-danger">
              {{ (((total * 10) / 100 + total) / 24) | formatPriceJP }}
              <span class="text-dark"
                >円/月額 <span class="text-small fst-italic"> ×24回</span></span
              >
            </span>
          </div>
          <div class="d-flex fs-5 justify-content-between">
            <span> 別途見積もり: </span>
            <span class="text-warning">
              {{ contactPrice }}
              <span class="text-dark">点</span>
            </span>
          </div>
          <div class="d-flex fs-5 justify-content-between">
            <span> オプション費用: </span>
            <span class="text-success">
              {{ totalOptionalFee | formatPriceJP }}
              <span class="text-dark">円/月額</span>
            </span>
          </div>
          <div class="mt-4 pt-3">
            <button
              class="btn btn-lg btn-primary m-auto d-flex align-items-center"
              @click="downloadPdf"
            >
              <img
                src="http://cteck.site/wp-content/themes/twentytwelve/images/dowload-pdf.png"
                height="20"
                style="width: 16px"
                class="me-2"
                alt=""
              />
              PDF エクスポート
            </button>
          </div>
        </label>
      </p>
    </div>

    <div class="mx-auto my-4 table-homepage">
      <table class="w-100">
        <thead>
          <tr>
            <th scope="col" class="fw-bold">No</th>
            <th scope="col" class="fw-bold text-start">項目</th>
            <th scope="col" class="fw-bold text-start">詳細内容</th>
            <th scope="col" class="fw-bold">単価</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items.filter(
              (item) => item != null || item != undefined
            )"
            :key="index"
          >
            <td scope="row" class="fw-bold">{{ index + 1 }}</td>
            <td class="text-start">{{ item.title }}</td>
            <td
              v-if="item.type === TYPE_ANSWER.selectMultiple"
              class="text-start"
              colspan="2"
            >
              <ul class="list-group">
                <li
                  v-for="(answer, id) in item.answers"
                  :key="id"
                  class="list-group-item d-flex justify-content-between align-items-center px-2 border-0"
                >
                  {{ answer.name }}
                  <span class="fs-6 text-end">{{
                    foramtPrice(answer.price, answer.typeAnswer).price
                  }}</span>
                </li>
              </ul>
            </td>
            <td v-else-if="item.type === TYPE_ANSWER.input" class="text-start">
              {{ Number.isNaN(item.quantily) ? 0 : item.quantily }}
            </td>
            <td v-else class="text-start">{{ item.answer }}</td>
            <td
              v-if="item.type !== TYPE_ANSWER.selectMultiple"
              class="text-price-homepage"
            >
              <span class="p-2 fs-6" :class="foramtPrice(item.price).class">{{
                foramtPrice(item.price, item.typeAnswer).price
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <a
      href="https://dreamcode.jp/contact/"
      class="btn-homepage btn btn-info btn-lg text-white mb-5 w-50"
    >
      <font-awesome-icon icon="fa-solid fa-envelope" class="me-1" />
      具体的な見積もりを依頼する
    </a>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      filename="見積もり"
      :paginate-elements-by-height="1100"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="true"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="pt-4 px-5 template-pdf">
        <div class="d-flex justify-content-between header-pdf pt-2">
          <h1 class="text-center mb-3 fw-bold text-white">見積もり</h1>
          <img
            src="http://cteck.site/wp-content/themes/twentytwelve/images/logo.png"
            alt=""
            style="max-width: 245px"
            height="74"
          />
        </div>
        <div class="d-flex justify-content-end location-pdf pt-4">
          <div class="d-flex flex-column justify-content-between w-25">
            <div class="d-flex justify-content-between">
              <span class="fw-bold"> 見積書番号: </span>
              <span class="fst-italic"> #{{ randomInvoice }} </span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="fw-bold"> 発行日: </span>
              <span class="fst-italic"> {{ todayDate }} </span>
            </div>
          </div>
        </div>
        <section class="pdf-item mt-4 content-pdf">
          <table class="border w-100">
            <thead>
              <tr>
                <th scope="col" class="fw-bold">No</th>
                <th scope="col" class="fw-bold">項目</th>
                <th scope="col" class="fw-bold">詳細内容</th>
                <th scope="col" class="fw-bold">単価</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in this.dataPageFirst" :key="index">
                <td scope="row" class="fw-bold">{{ index + 1 }}</td>
                <td class="text-start">{{ item.title }}</td>
                <td
                  v-if="item.type === TYPE_ANSWER.selectMultiple"
                  class="text-start"
                  colspan="2"
                >
                  <ul class="list-group">
                    <li
                      v-for="(answer, id) in item.answers"
                      :key="id"
                      class="list-group-item d-flex justify-content-between align-items-center px-2 border-0"
                    >
                      {{ answer.name }}
                      <span class="fs-6 text-end">{{
                        foramtPrice(answer.price, answer.typeAnswer).price
                      }}</span>
                    </li>
                  </ul>
                </td>
                <td
                  v-else-if="item.type === TYPE_ANSWER.input"
                  class="text-start"
                >
                  {{ Number.isNaN(item.quantily) ? 0 : item.quantily }}
                </td>
                <td v-else class="text-start">{{ item.answer }}</td>
                <td
                  v-if="item.type !== TYPE_ANSWER.selectMultiple"
                  class="text-end"
                >
                  <span
                    class="p-2 fs-6 text-end"
                    :class="foramtPrice(item.price).class"
                    >{{ foramtPrice(item.price, item.typeAnswer).price }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="this.dataPageSecond.length > 0" class="mt-5">
            <div class="html2pdf__page-break" />
            <table class="border w-100 mt-5">
              <tbody>
                <tr v-for="(item, index) in this.dataPageSecond" :key="index">
                  <td scope="row" class="fw-bold">
                    {{ index + dataPageFirst.length }}
                  </td>
                  <td class="text-start">{{ item.title }}</td>
                  <td
                    v-if="item.type === TYPE_ANSWER.selectMultiple"
                    class="text-start"
                    colspan="2"
                  >
                    <ul class="list-group">
                      <li
                        v-for="(answer, id) in item.answers"
                        :key="id"
                        class="list-group-item d-flex justify-content-between align-items-center px-2 border-0"
                      >
                        {{ answer.name }}
                        <span class="fs-6 text-end">{{
                          foramtPrice(answer.price, answer.typeAnswer).price
                        }}</span>
                      </li>
                    </ul>
                  </td>
                  <td
                    v-else-if="item.type === TYPE_ANSWER.input"
                    class="text-start"
                  >
                    {{ Number.isNaN(item.quantily) ? 0 : item.quantily }}
                  </td>
                  <td v-else class="text-start">{{ item.answer }}</td>
                  <td
                    v-if="item.type !== TYPE_ANSWER.selectMultiple"
                    class="text-end"
                  >
                    <span
                      class="p-2 fs-6 text-end"
                      :class="foramtPrice(item.price).class"
                      >{{
                        foramtPrice(item.price, item.typeAnswer).price
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <div class="d-flex justify-content-center mt-5 footer-pdf">
          <div class="w-100">
            <h3 class="mb-4 text-center fw-bold">シミュレーション結果</h3>
            <h6 class="d-flex justify-content-between">
              <span> 小計: </span>
              <span class="text-success">
                {{ total | formatPriceJP }}
                <span class="text-dark">円</span></span
              >
            </h6>
            <h6 class="d-flex justify-content-between">
              <span> 消費税(10%): </span>
              <span class="text-success">
                {{ ((total * 10) / 100) | formatPriceJP }}
                <span class="text-dark">円</span></span
              >
            </h6>
            <h4
              class="d-flex justify-content-between border-top border-info border-2 mt-3"
            >
              <span> 制作費用 合計: </span>
              <span class="text-primary">
                {{ ((total * 10) / 100 + total) | formatPriceJP }}
                <span class="text-dark">円</span></span
              >
            </h4>
            <h6 class="d-flex justify-content-between">
              <span> 月々の支払: </span>
              <span class="text-danger">
                {{ (((total * 10) / 100 + total) / 24) | formatPriceJP }}
                <span class="text-dark"
                  >円/月額
                  <span class="text-small fst-italic"> ×24回</span></span
                >
              </span>
            </h6>
            <h6 class="d-flex justify-content-between">
              <span> 別途見積もり: </span>
              <span class="text-warning fs-4">
                {{ contactPrice }}
                <span class="text-success">点</span>
              </span>
            </h6>
            <h6 class="d-flex justify-content-between">
              <span> オプション費用: </span>
              <span class="text-success">
                {{ totalOptionalFee | formatPriceJP }}
                <span class="text-dark">円/月額</span>
              </span>
            </h6>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { TYPE_ANSWER } from "@/modules/homePage/constants.js";

var numeral = require("numeral");

/**
 * Number format for real number type
 *
 * @return [ Number ]
 */
function formatNumberDecimal(value) {
  return numeral(value).format("0,0");
}

export default {
  data() {
    return {
      total: 0,
      totalOptionalFee: 0,
      contactPrice: 0,
      todayDate: "",
      randomInvoice: 0,
      dataPageFirst: [],
      dataPageSecond: [],
    };
  },
  props: ["items"],
  created() {
    for (let i = 0; i < this.items.length; i++) {
      const element = this.items[i];
      if (element != undefined) {
        if (element.type == 2) {
          element.answers.forEach((answer) => {
            if (parseInt(answer.price) && answer.typeAnswer === "MONTHLY") {
              this.totalOptionalFee += answer.price;
            } else if (parseInt(answer.price) && answer.typeAnswer === "") {
              this.total += answer.price;
            } else {
              if (answer.price === "別途見積もり") {
                this.contactPrice += 1;
              }
            }
          });
        } else {
          if (
            (parseInt(element.price) || element.price === 0) &&
            element.typeAnswer === "MONTHLY"
          ) {
            this.totalOptionalFee += element.price;
          } else if (
            (parseInt(element.price) || element.price === 0) &&
            element.typeAnswer === ""
          ) {
            this.total += element.price;
          } else {
            this.contactPrice += 1;
          }
        }
      }
    }

    // get today
    var today = new Date();
    this.todayDate =
      today.getFullYear() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getDate();

    // random invoice
    this.randomInvoice = Math.floor(Math.random() * 1010);

    this.items.sort((question1, question2) => {
      return question1.orderBy - question2.orderBy;
    });

    this.formatData();
  },
  methods: {
    formatData() {
      let countRecord = 1;
      let maxRecordPage = 15;
      for (let j = 0; j < this.items.length; j++) {
        const element = this.items[j];
        if (element.type == 2) {
          if (countRecord + element.answers.length <= maxRecordPage) {
            this.dataPageFirst.push(this.items[j]);
            countRecord += element.answers.length;
          } else {
            if (countRecord > maxRecordPage) {
              this.dataPageSecond.push(this.items[j]);
            } else {
              // Get data page 1
              const elementType2_Data1 = Object.assign({}, element);
              elementType2_Data1.answers = elementType2_Data1.answers.slice(
                0,
                maxRecordPage - countRecord
              );
              // Get data page 2
              const elementType2_Data2 = Object.assign({}, element);
              elementType2_Data2.answers = elementType2_Data2.answers.slice(
                maxRecordPage - countRecord,
                this.items[j].length
              );
              // Push data
              this.dataPageFirst.push(elementType2_Data1);
              this.dataPageSecond.push(elementType2_Data2);
              // Out for
              countRecord += 999;
            }
          }
        } else {
          if (countRecord <= maxRecordPage) {
            this.dataPageFirst.push(this.items[j]);
            countRecord++;
          }
        }
      }
    },
    /**
     * Export PDF
     *
     */
    async downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },

    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.44,
              pdf.internal.pageSize.getHeight() - 0.3
            );
          }
        })
        .save();
    },

    foramtPrice(price, typeAnswer) {
      if (parseInt(price) && typeAnswer === "MONTHLY") {
        return {
          price:
            "月額 " +
            formatNumberDecimal(parseInt(price) || price === 0).toString() +
            " 円",
          class: "",
        };
      } else if (parseInt(price) && typeAnswer === "") {
        return {
          price:
            formatNumberDecimal(parseInt(price) || price === 0).toString() +
            " 円",
          class: "",
        };
      }
      return {
        price: price,
        class: "",
      };
    },
  },
  computed: {
    TYPE_ANSWER() {
      return TYPE_ANSWER;
    },
  },
};
</script>

<style scoped>
.text-small {
  font-size: 15px;
}
.box-homepage {
  border-top: 3px solid #2eb85c;
  border-radius: 5px;
  box-shadow: 0px 10px 40px -10px #a3a5ae;
  padding: 30px;
}
.box-homepage label {
  color: #2eb85c;
}
.box-homepage h3 {
  color: #4c4e61;
}
.box-homepage span {
  color: #a3a5ae;
}
.box-homepage .p {
  font-size: 14px;
}
@media (min-width: 992px) {
  .box-homepage,
  .table-homepage {
    width: 75%;
  }
}
.btn-homepage svg {
  font-size: 24px;
}
@media (max-width: 767px) {
  .btn-homepage {
    width: 100% !important;
  }
}
@media (max-width: 575px) {
  .box-homepage {
    padding: 25px 0px;
  }
  .table-homepage {
    overflow-x: scroll;
  }
  .table-homepage table {
    min-width: 950px !important;
  }
  .box-homepage .w-75 {
    width: 95% !important;
  }
}

.table-homepage {
  box-shadow: 0px 10px 40px -10px #a3a5ae;
}
.table-homepage thead {
  border-bottom: 2px solid #0dcaf0;
}
.table-homepage thead tr th {
  margin: 0;
  padding: 0.75rem;
}
.table-homepage tbody tr {
  border-top: 1px solid #dee2e6;
}
.table-homepage tbody tr td {
  padding: 0.75rem;
  margin: 0;
}
.text-price-homepage {
  text-align: -webkit-right;
}
.badge {
  display: table-caption !important;
}
.template-pdf {
  background: url("http://cteck.site/wp-content/themes/twentytwelve/images/bg-pdf.png");
  background-repeat: no-repeat;
}
.template-pdf .content-pdf {
  min-height: 450px;
}
.template-pdf .content-pdf thead {
  border-bottom: 2px solid #0dcaf0;
}
.template-pdf .content-pdf thead tr th {
  margin: 0;
  padding: 0.75rem;
}
.template-pdf .content-pdf tbody tr {
  border-top: 1px solid #dee2e6;
}
.template-pdf .content-pdf tbody tr td {
  padding: 0.75rem;
  margin: 0;
}
</style>
