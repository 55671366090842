<template>
  <div>
    <div
      class="input-group mb-3"
      v-for="(answer, indexAns) in question.answers"
      :key="indexAns"
    >
      <div class="wrap">
        <div>
          <label for="fname" id="fname-label">{{ answer.name }}</label>
          <input
            id="fname"
            type="text"
            v-model="quantily"
            class="form-control text-end text-white input-fname"
          />
        </div>
        <div class="w-100 d-flex justify-content-end my-2" v-if="isContact">
          <button
            class="btn-homepage btn btn-outline-success py-2 px-sm-4 px-1"
            type="button"
            @click="onNextQuestion(false)"
          >
            <font-awesome-icon
              icon="fa-solid fa-file-signature"
              class="m-0 me-sm-2"
            />
            相談したい
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataInput: {
        title: "",
        question: "",
        answer: "",
        price: "",
        type: "",
        typeAnswer: "",
      },
      contactPrice: "別途見積もり",
      quantily: "",
    };
  },
  props: ["question", "isContact"],
  methods: {
    /**
     * Move on to the next question
     */
    onNextQuestion(status) {
      const submit = this.dataInput;
      if (status) {
        try {
          if (!Number.isNaN(this.question.answers[0].price * this.quantily)) {
            submit.price = this.question.answers[0].price * this.quantily;
            submit.quantily = parseInt(this.quantily);
          } else {
            submit.price = 0;
            submit.quantily = 0;
          }
        } catch (error) {
          submit.price = 0;
        }
      } else {
        submit.price = this.contactPrice;
        this.$emit("on-next", true);
        submit.quantily = 0;
      }
      submit.question = this.question.name;
      submit.title = this.question.title;
      submit.answer = this.question.answers[0].name;
      submit.type = this.question.typeQuestion;
      submit.typeAnswer = this.question.answers[0].typeAnswer;
      submit.orderBy = 2;

      this.$emit("on-submit", submit);
    },
  },

  watch: {
    quantily: {
      handler: function () {
        this.onNextQuestion(true);
      },
    },
  },

  mounted() {
    // Js effect input
    document.getElementById("fname").addEventListener("focusin", function () {
      document.getElementById("fname-label").classList.add("active");
    });
    document.getElementById("fname").addEventListener("focusout", function () {
      if (document.getElementById("fname") != null) {
        if (!document.getElementById("fname").value) {
          document.getElementById("fname-label").classList.remove("active");
        }
      }
    });
  },
};
</script>

<style scoped>
div.wrap {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
}
@media (max-width: 768px) {
  div.wrap {
    width: 100%;
  }
}

div.wrap div {
  position: relative;
}

label {
  position: absolute;
  top: 0;
  font-size: 20px;
  margin: 10px;
  padding: 0 10px;
  -webkit-transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
  transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
  border-radius: 3px;
  display: block;
  background-color: #fff;
  color: #183153;
  font-weight: bold;
}

.active {
  top: -25px;
  font-size: 16px;
}
.input-fname {
  border-radius: 8px;
}

input[type="text"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #63e6be;
  font-size: 26px;
  font-weight: bold;
  color: #183153 !important;
  outline: none;
}

input[type="text"]:focus {
  outline: none !important;
  box-shadow: none;
}
@media (max-width: 768px) {
  .btn-homepage {
    width: 100% !important;
  }
}
.btn-homepage {
  border-radius: 8px;
  width: 500px;
  height: 55px;
  border-top: 2px solid #198754;
}
.btn-homepage:hover {
  color: #fff;
}
.btn-homepage svg {
  font-size: 25px;
}
</style>
