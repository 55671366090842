import Vue from 'vue'
import VueRouter from 'vue-router'
//Page
import Home from '../pages/Home.vue'
import Compare from '../pages/Compare.vue'
import Service from '../pages/Service.vue'
import HomePage from '../pages/HomePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '費用対効果 試算表',
    component: Compare
  },
  {
    path: '/compare',
    name: '費用対効果 試算表',
    component: Compare
  },
  {
    path: '/home',
    name: 'WEBマーケティング　見積もり',
    component: Home
  },
  {
    path: '/service',
    name: '効果測定シュミレーション',
    component: Service
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../pages/Service.vue')
  },
  {
    path: '/homePage',
    name: 'HomePage',
    component: HomePage
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
