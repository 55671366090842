<template>
  <layout-default>
    <compare-form></compare-form>
    <div class="bg-al"></div>
  </layout-default>
</template>

<script>
// Layout
import LayoutDefault from "@/layouts/default.vue";

import CompareForm from "@/modules/compare/components/CompareForm.vue";

export default {
  name: "Compare",
  components: {
    LayoutDefault,
    CompareForm,
  }
};
</script>
