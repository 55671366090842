<template>
  <div class="p-0" id="compare">
    <h2 class="title-page">WEBマーケティング 見積もり</h2>
    <div class="loadding-overlay" v-if="isLoadding === true">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div class="page-compare p-3 border-0 m-auto">
      <div
        class="row d-flex justify-content-between justify-content-md-around align-items-center pb-3 mx-0"
      >
        <div
          class="col-3 d-flex justify-content-between flex-column align-items-center"
        >
          <vue-ellipse-progress
            :progress="competitiveWebsiteSeoScore"
            :color="colorProgressCompetitive"
            emptyColor="#0dcaf0"
            emptyThickness="10%"
            :legendValue="competitiveWebsiteSeoScore"
            :size="sizeBoxProgress"
            class="fs-1 fw-bold text-dark calculate-score-seo"
          >
            <span slot="legend-value"></span>
          </vue-ellipse-progress>
          <p class="mb-0 mt-2 title-box">競合サイトの評価</p>
        </div>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="minus"
          class="col svg-inline--fa fa-minus fa-w-14 icon-box-seo"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          width="40"
          height="40"
          color="aquamarine"
        >
          <path
            fill="currentColor"
            d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
          ></path>
        </svg>
        <div
          class="col-3 d-flex justify-content-between flex-column align-items-center"
        >
          <vue-ellipse-progress
            :progress="websiteSeoScore"
            :color="colorProgress"
            emptyColor="#0dcaf0"
            :legendValue="websiteSeoScore"
            emptyThickness="10%"
            :size="sizeBoxProgress"
            class="fs-1 fw-bold text-dark calculate-score-seo"
          >
            <span slot="legend-value"> </span>
          </vue-ellipse-progress>
          <p class="mb-0 mt-2 title-box">サイト評価</p>
        </div>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="grip-lines"
          class="col svg-inline--fa fa-grip-lines fa-w-16 icon-box-seo"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="40"
          height="40"
          color="aquamarine"
        >
          <path
            fill="currentColor"
            d="M496 288H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-128H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"
          ></path>
        </svg>
        <div
          class="col-3 col-md-4 d-flex justify-content-between flex-column align-items-center"
        >
          <vue-ellipse-progress
            :progress="100"
            color="#2eb85c"
            emptyColor="#0dcaf0"
            emptyThickness="10%"
            :size="sizeBoxProgress"
            class="fs-1 fw-bold text-dark calculate-score-seo"
          >
            <number
              ref="seoDeviant"
              :from="0"
              :to="seoDeviant"
              :duration="1"
              easing="Power1.easeOut"
            />
          </vue-ellipse-progress>
          <p class="mb-0 mt-2 title-box">サイト評価と競合サイトの評価の差</p>
        </div>
      </div>
      <div class="row pt-5 pt-sm-0">
        <div class="col-12 d-flex justify-content-between compare-link m-0">
          <div class="row w-100 m-0">
            <div class="col-12 pb-3 p-0">
              <h4 class="text-start">競合サイトのURL</h4>

              <input
                class="fw-bold form-control border-success"
                v-model="urlSeoCompetitive"
              />
            </div>
            <div class="col-12 p-0">
              <h4 class="text-start">サイトのURL</h4>
              <input
                class="fw-bold form-control border-success"
                v-model="urlSeo"
              />
            </div>
          </div>
        </div>
        <div
          class="col-12 d-flex justify-content-center align-items-center pt-3 pb-5"
        >
          <button
            type="button"
            class="btn btn-success btn-lg"
            @click="submitSeo()"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="calculator"
              class="svg-inline--fa fa-calculator fa-w-14 mb-1"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="20"
              height="20"
            >
              <path
                fill="currentColor"
                d="M400 0H48C22.4 0 0 22.4 0 48v416c0 25.6 22.4 48 48 48h352c25.6 0 48-22.4 48-48V48c0-25.6-22.4-48-48-48zM128 435.2c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8V268.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v166.4zm0-256c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8V76.8C64 70.4 70.4 64 76.8 64h294.4c6.4 0 12.8 6.4 12.8 12.8v102.4z"
              ></path>
            </svg>
            SEO点数を計算
          </button>
        </div>
      </div>
      <div class="my-3">
        <div
          v-for="question in LIST_QUESTION"
          :key="question.id"
          class="form-question"
        >
          <select
            class="form-select w-55 bg-info border-info text-white mx-auto"
            aria-label=" example"
            v-if="question.type == TYPE_INPUT.selectOption"
            v-model="dataInput[question.id]"
          >
            <option selected value="">{{ question.name }}</option>
            <option
              v-for="answer in question.answer"
              :key="answer.value"
              :value="answer.value"
            >
              {{ answer.name }}
            </option>
          </select>
          <div
            v-if="question.type == TYPE_INPUT.selectOption && question.id === 2"
            class="mx-auto w-55 text-end"
          >
            <a
              href="https://www.four-class.jp/service/estimate/"
              target="blank"
              class="text-decoration-none text-primary fst-italic"
              style="font-size: 12px"
              >参考：他社相場費用
            </a>
          </div>
          <div
            v-if="question.type == TYPE_INPUT.selectOption && question.id === 5"
            class="mx-auto w-55 text-end"
          >
            <span class="text-primary fst-italic" style="font-size: 12px"
              >注：ターゲット人口＝WEBユーザー人口と月間数から算出</span
            >
          </div>
          <div
            v-if="question.type == TYPE_INPUT.selectOption"
            class="form-calculation text-center my-2"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="plus"
              class="svg-inline--fa fa-plus fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="14"
              height="14"
            >
              <path
                fill="currentColor"
                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
              ></path>
            </svg>
          </div>
          <div
            class="input-group w-55 mx-auto"
            v-if="question.type == TYPE_INPUT.text"
          >
            <span
              class="input-group-text bg-info text-white btn-info col-9"
              id="basic-addon1"
              >{{ question.name }}</span
            >
            <input
              v-model="dataInput[question.id]"
              type="number"
              class="form-control text-end border-info item-question-input"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div v-if="question.type == TYPE_INPUT.text" class="text-center my-2">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="plus"
              class="svg-inline--fa fa-plus fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="14"
              height="14"
            >
              <path
                fill="currentColor"
                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      <h3 class="pt-2 m-0 text-center fw-bold">オプション</h3>
      <input
        type="checkbox"
        id="box-img"
        class="d-none"
        v-model="isActive[0]"
      />
      <input
        type="checkbox"
        id="box-img-1"
        class="d-none"
        v-model="isActive[1]"
      />
      <input
        type="checkbox"
        id="box-img-2"
        class="d-none"
        v-model="isActive[2]"
      />
      <input
        type="checkbox"
        id="box-img-3"
        class="d-none"
        v-model="isActive[3]"
      />
      <input
        type="checkbox"
        id="box-img-4"
        class="d-none"
        v-model="isActive[4]"
      />
      <input
        type="checkbox"
        id="box-img-5"
        class="d-none"
        v-model="isActive[5]"
      />
      <div class="my-3 box">
        <div class="row align-items-center justify-content-between">
          <div class="col-6 w-md-30 w-lg-30 py-2">
            <label
              for="box-img"
              class="border-0 p-0 d-block position-relative"
              v-bind:class="{ active: isActive[0] }"
            >
              <p
                class="position-absolute d-flex justify-content-center align-items-center text-white text-center fw-bold w-100 h-100 px-1 m-0"
              >
                診断フォーム
              </p>
              <span></span>
            </label>
          </div>
          <div class="box-icon text-center px-0">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="plus"
              class="svg-inline--fa fa-plus fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="14"
              height="14"
            >
              <path
                fill="currentColor"
                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
              ></path>
            </svg>
          </div>
          <div class="col-6 w-md-30 w-lg-30 py-2">
            <label
              for="box-img-1"
              class="border-0 p-0 d-block position-relative"
              v-bind:class="{ active: isActive[1] }"
            >
              <p
                class="position-absolute d-flex justify-content-center align-items-center text-white text-center fw-bold w-100 h-100 px-1 m-0"
              >
                オンライン予約システム
              </p>
              <span></span>
            </label>
          </div>
          <div class="box-icon text-center px-0">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="plus"
              class="svg-inline--fa fa-plus fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="14"
              height="14"
            >
              <path
                fill="currentColor"
                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
              ></path>
            </svg>
          </div>
          <div class="col-6 w-md-30 w-lg-30 py-2">
            <label
              for="box-img-2"
              class="border-0 p-0 d-block position-relative"
              v-bind:class="{ active: isActive[2] }"
            >
              <p
                class="position-absolute d-flex justify-content-center align-items-center text-white text-center fw-bold w-100 h-100 px-1 m-0"
              >
                オンラインカード決済システム
              </p>
              <span></span>
            </label>
          </div>
          <div class="box-icon d-none text-center mx-2 px-0">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="plus"
              class="svg-inline--fa fa-plus fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="14"
              height="14"
            >
              <path
                fill="currentColor"
                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
              ></path>
            </svg>
          </div>
          <div class="col-6 w-md-30 w-lg-30 py-2">
            <label
              for="box-img-3"
              class="border-0 p-0 d-block position-relative"
              v-bind:class="{ active: isActive[3] }"
            >
              <p
                class="position-absolute d-flex justify-content-center align-items-center text-white text-center fw-bold w-100 h-100 px-1 m-0"
              >
                サイト修正＆デザインアップ
              </p>
              <span></span>
            </label>
          </div>
          <div class="box-icon text-center px-0">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="plus"
              class="svg-inline--fa fa-plus fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="14"
              height="14"
            >
              <path
                fill="currentColor"
                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
              ></path>
            </svg>
          </div>
          <div class="col-6 w-md-30 w-lg-30 py-2">
            <label
              for="box-img-4"
              class="border-0 p-0 d-block position-relative"
              v-bind:class="{ active: isActive[4] }"
            >
              <p
                class="position-absolute d-flex justify-content-center align-items-center text-white text-center fw-bold w-100 h-100 px-1 m-0"
              >
                動画オプション
              </p>
              <span></span>
            </label>
          </div>
          <div class="box-icon text-center px-0">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="plus"
              class="svg-inline--fa fa-plus fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="14"
              height="14"
            >
              <path
                fill="currentColor"
                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
              ></path>
            </svg>
          </div>
          <div class="col-6 w-md-30 w-lg-30 py-2">
            <label
              for="box-img-5"
              class="border-0 p-0 d-block position-relative"
              v-bind:class="{ active: isActive[5] }"
            >
              <p
                class="position-absolute d-flex justify-content-center align-items-center text-white text-center fw-bold w-100 h-100 px-1 m-0"
              >
                指示書・レポート作成
              </p>
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div class="mt-5 mb-3 d-flex justify-content-center box-btn-show-result">
        <button
          type="button"
          class="btn btn-success btn-lg"
          @click="delegateShowResult"
          id="btn-show-result"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-down"
            class="svg-inline--fa fa-arrow-down fa-w-14 mb-1"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width="20px"
            height="20px"
          >
            <path
              fill="currentColor"
              d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
            ></path>
          </svg>
          見積りを表示する
        </button>
      </div>
      <div class="my-5" id="waper-total">
        <div class="row">
          <div class="col-12 col-sm-6 my-2">
            <div
              class="box-payment bg-white text-black text-left"
              id="box-result-competitor"
            >
              <h3 class="m-0 mb-4 fw-bold text-center">業界相場費用</h3>
              <p class="d-flex justify-content-lg-between text-payment">
                <label class="title">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="city"
                    class="svg-inline--fa fa-city fa-w-16 me-2"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    width="22px"
                    height="22px"
                    color="#f9b115"
                  >
                    <path
                      fill="currentColor"
                      d="M616 192H480V24c0-13.26-10.74-24-24-24H312c-13.26 0-24 10.74-24 24v72h-64V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v80h-64V16c0-8.84-7.16-16-16-16H80c-8.84 0-16 7.16-16 16v80H24c-13.26 0-24 10.74-24 24v360c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V216c0-13.26-10.75-24-24-24zM128 404c0 6.63-5.37 12-12 12H76c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12H76c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12H76c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm128 192c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm160 96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12V76c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm160 288c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40zm0-96c0 6.63-5.37 12-12 12h-40c-6.63 0-12-5.37-12-12v-40c0-6.63 5.37-12 12-12h40c6.63 0 12 5.37 12 12v40z"
                    ></path>
                  </svg>
                  <span>業界相場費用（年間契約）: </span>
                </label>
                <label class="text-end">
                  <span class="fs-5 price fw-bold text-warning">{{
                    total | formatPriceJP
                  }}</span>
                  円
                </label>
              </p>

              <p class="d-flex justify-content-lg-between text-payment">
                <label class="title">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="mix"
                    class="svg-inline--fa fa-mix fa-w-16 me-2"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    width="22px"
                    height="22px"
                    color="#3399ff"
                  >
                    <path
                      fill="currentColor"
                      d="M0 64v348.9c0 56.2 88 58.1 88 0V174.3c7.9-52.9 88-50.4 88 6.5v175.3c0 57.9 96 58 96 0V240c5.3-54.7 88-52.5 88 4.3v23.8c0 59.9 88 56.6 88 0V64H0z"
                    ></path>
                  </svg>
                  <span>成果報酬プラン:</span>
                </label>
                <label class="text-end">
                  <span class="price fw-bold text-info">{{
                    calAddTotalCRV() | formatPriceJP
                  }}</span>
                  円
                </label>
              </p>

              <p class="d-flex justify-content-lg-between text-payment m-0">
                <label class="title">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="building"
                    class="svg-inline--fa fa-building fa-w-14 me-2"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    width="22px"
                    height="22px"
                    color="#321fdb"
                  >
                    <path
                      fill="currentColor"
                      d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"
                    ></path>
                  </svg>
                  <span>大手企業費用相場:</span>
                </label>
                <label class="text-end">
                  <span class="price fw-bold text-primary"
                    >{{ calMinPrice(total + totalPriceCRV) | formatPriceJP }}
                  </span>
                  円 -
                  <span class="price fw-bold text-primary"
                    >{{ calMaxPrice(total + totalPriceCRV) | formatPriceJP }}
                  </span>
                  円
                </label>
              </p>
            </div>
          </div>
          <div class="col-12 col-sm-6 my-2">
            <div
              class="box-payment d-flex justify-content-between flex-column bg-white text-black text-left h-100"
              id="box-result-mine"
            >
              <h3 class="m-0 mb-4 fw-bold text-center">DCプラン 料金</h3>
              <p class="text-center fw-bold m-0">
                <label class="fs-1"
                  ><span class="text-danger">{{
                    (total * 0.77) | formatPriceJP
                  }}</span>
                  円</label
                >
              </p>
              <p class="d-flex justify-content-between fw-bold m-0">
                <span class="fs-3">月額プラン</span>
                <span class="text-success fs-3"
                  ><span class="text-danger"
                    >{{ ((total * 0.77) / 24) | formatPriceJP }} </span
                  >円</span
                >
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 my-2">
            <div
              class="box-payment d-flex justify-content-between flex-column bg-white text-black text-left h-100"
              id="box-result-competitor-bot"
            >
              <h3 class="m-0 mb-4 fw-bold text-center">競合企業プラン 料金</h3>
              <p class="text-center fw-bold m-0">
                <label class="fs-1"
                  ><span class="text-danger">{{ total | formatPriceJP }}</span>
                  円</label
                >
              </p>
              <p class="d-flex justify-content-between fw-bold m-0">
                <span class="fs-3">月額プラン</span>
                <span class="text-success fs-3"
                  ><span class="text-danger"
                    >{{ (total / 24) | formatPriceJP }} </span
                  >円</span
                >
              </p>
            </div>
          </div>
          <div class="col-12 col-sm-6 my-2">
            <div
              class="box-payment d-flex justify-content-between flex-column bg-white text-black text-left h-100"
              id="box-result-company-big"
            >
              <h3 class="m-0 mb-4 fw-bold text-center">大手企業プラン 料金</h3>
              <p class="text-center fw-bold m-0">
                <label class="fs-1"
                  ><span class="text-danger">{{
                    (total * 1.3) | formatPriceJP
                  }}</span>
                  円</label
                >
              </p>
              <p class="d-flex justify-content-between fw-bold m-0">
                <span class="fs-3">月額プラン</span>
                <span class="text-success fs-3"
                  ><span class="text-danger"
                    >{{ ((total * 1.3) / 24) | formatPriceJP }} </span
                  >円</span
                >
              </p>
            </div>
          </div>
        </div>

        <div class="mt-4 pt-3">
          <div id="btn-export-pdf">
            <button
              class="btn btn-lg btn-primary m-auto d-flex align-items-center"
              @click="downloadPdf"
            >
              <img
                src="http://cteck.site/wp-content/themes/twentytwelve/images/dowload-pdf.png"
                height="20"
                style="width: 16px"
                class="me-2"
                alt=""
              />
              PDF エクスポート
            </button>
          </div>
        </div>
      </div>

      <div>
        <h3 class="text-end text-black">
          <button class="btn btn-info px-4">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="hand-point-right"
              class="svg-inline--fa fa-hand-point-right fa-w-16 me-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="20px"
              height="20px"
              color="white"
            >
              <path
                fill="currentColor"
                d="M512 199.652c0 23.625-20.65 43.826-44.8 43.826h-99.851c16.34 17.048 18.346 49.766-6.299 70.944 14.288 22.829 2.147 53.017-16.45 62.315C353.574 425.878 322.654 448 272 448c-2.746 0-13.276-.203-16-.195-61.971.168-76.894-31.065-123.731-38.315C120.596 407.683 112 397.599 112 385.786V214.261l.002-.001c.011-18.366 10.607-35.889 28.464-43.845 28.886-12.994 95.413-49.038 107.534-77.323 7.797-18.194 21.384-29.084 40-29.092 34.222-.014 57.752 35.098 44.119 66.908-3.583 8.359-8.312 16.67-14.153 24.918H467.2c23.45 0 44.8 20.543 44.8 43.826zM96 200v192c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V200c0-13.255 10.745-24 24-24h48c13.255 0 24 10.745 24 24zM68 368c0-11.046-8.954-20-20-20s-20 8.954-20 20 8.954 20 20 20 20-8.954 20-20z"
              ></path>
            </svg>
          </button>
        </h3>
      </div>
    </div>
    <div class="page-compare p-3 border-0 m-auto">
      <p class="text-black fw-bold mb-0">※注</p>
      <a
        href="https://semlabo.com/seo/blog/seo-expence/"
        class="text-black fw-bold text-decoration-none text-info"
        target="_blank"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="link"
          class="svg-inline--fa fa-link fa-w-16 me-2"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="20px"
          height="20px"
          color="#31d2f2"
        >
          <path
            fill="currentColor"
            d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
          ></path>
        </svg>
        <span class="text-info">業界相場・競合他社</span>
      </a>
      <p class="mb-0 mt-1">
        調査企業：
        GMOインターネットグループ、株式会社オプト、株式会社セプテーニなど業界有名企業様から調査
      </p>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      filename="見積もり"
      :paginate-elements-by-height="1100"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="false"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="px-5 template-pdf">
        <div class="d-flex justify-content-between header-pdf">
          <h1 class="text-center mb-3 fw-bold text-white">見積もり</h1>
          <img
            src="http://cteck.site/wp-content/themes/twentytwelve/images/logo.png"
            alt=""
            style="max-width: 245px"
            height="74"
          />
        </div>
        <div class="d-flex justify-content-end location-pdf">
          <div class="d-flex flex-column justify-content-between w-25">
            <div class="d-flex justify-content-between">
              <span class="fw-bold"> 見積書番号: </span>
              <span class="fst-italic"> #{{ randomInvoice }} </span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="fw-bold"> 発行日: </span>
              <span class="fst-italic"> {{ todayDate }} </span>
            </div>
          </div>
        </div>
        <section class="pdf-item mt-4 content-pdf">
          <table class="border w-100">
            <thead>
              <tr>
                <th scope="col" class="fw-bold">No</th>
                <th scope="col" class="fw-bold">項目</th>
                <th scope="col" class="fw-bold">詳細内容</th>
                <th scope="col" class="fw-bold">単価</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in dataExport"
                :index="index"
                :key="item.id"
              >
                <td scope="row">{{ index + 1 }}</td>
                <td>{{ item.nameQue }}</td>
                <td>{{ item.nameAns }}</td>
                <td class="text-end">{{ item.price | formatPriceJP }}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <div
          class="d-flex flex-column align-items-center justify-content-between footer-pdf pt-3"
        >
          <div class="w-75">
            <h4 class="mb-3 text-center fw-bold">業界相場費用</h4>
            <h6 class="d-flex justify-content-between">
              <span> 業界相場費用（年間契約）: </span>
              <span class="text-warning">
                {{ total | formatPriceJP }}
                <span class="text-success">円</span></span
              >
            </h6>
            <h6 class="d-flex justify-content-between">
              <span> 成果報酬プラン: </span>
              <span class="text-info">
                {{ calAddTotalCRV() | formatPriceJP }}
                <span class="text-success">円</span></span
              >
            </h6>
            <h6 class="d-flex justify-content-between">
              <span> 大手企業費用相場: </span>
              <span class="text-primary">
                {{ calMinPrice(total + totalPriceCRV) | formatPriceJP }}
                <span class="text-success">円</span> -
                {{ calMaxPrice(total + totalPriceCRV) | formatPriceJP }}
                <span class="text-success">円</span></span
              >
            </h6>
          </div>
          <div class="w-75 pt-3">
            <h4 class="mb-3 text-center fw-bold">DCプラン 料金</h4>
            <h6 class="d-flex justify-content-between">
              <span> 月額プラン: </span>
              <span class="text-danger">
                {{ ((total * 0.77) / 24) | formatPriceJP }}
                <span class="text-success">円</span>
              </span>
            </h6>
            <h4
              class="d-flex justify-content-between mt-2 border-top border-info"
            >
              <span> 合計: </span>
              <span class="text-danger fs-5">
                {{ (total * 0.77) | formatPriceJP }}
                <span class="text-success">円</span>
              </span>
            </h4>
          </div>
        </div>
        <!-- <div class="html2pdf__page-break" /> phan trang -->
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
// Library constants
import {
  LIST_COMPARE,
  LIST_QUESTION,
  UNIT_PRICE,
  PRICE_OPTIONS,
} from "@/modules/home/constants.js";
import { TYPE_INPUT, AXIOS_API_SEO } from "@/constants";

export default {
  // Data declaration
  data() {
    return {
      isActive: [false, false, false, false, false, false],
      isActiveSeo: true,
      isActiveSeoCompetitive: true,
      isActiveSeoBox: false,
      dataInput: [],
      totalPriceSelect: 0,
      totalPriceOption: 0,
      totalPriceCompete: 0,
      total: 0,
      totalPriceCRV: 0,
      siteRating: null,
      competitiveSiteReviews: null,
      delegateShowResult: () => {},
      delegateResetEffectShowResult: () => {},
      websiteSeoScore: 0,
      competitiveWebsiteSeoScore: 0,
      colorProgress: "#e55353",
      colorFillProgress: "#dc35457a",
      colorProgressCompetitive: "#e55353",
      colorFillProgressCompetitive: "#dc35457a",
      urlSeo: null,
      urlSeoCompetitive: null,
      isLoadding: false,
      competitiveWebsiteSeoScoreApiResult: 0,
      websiteSeoScoreApiResult: 0,
      seoDeviant: 0,
      sizeBoxProgress: 100,
      listPrice: [],
      listPriceOption: [],
      dataExport: [],
      todayDate: "",
      randomInvoice: 0,
    };
  },
  watch: {
    /**
     * Watch change value dataInput
     *
     */
    dataInput: {
      handler: function () {
        this.calculateSelectOption();
        this.delegateResetEffectShowResult();
      },
      deep: true,
    },
    /**
     * Watch change value option
     *
     */
    isActive: {
      handler: function () {
        this.calculateOptionSystem();
        this.calculateSelectOption();
        this.delegateResetEffectShowResult();
      },
      deep: true,
    },
    /**
     * Watch change value totalPriceOption
     *
     */
    totalPriceOption: function (val) {
      this.delegateResetEffectShowResult();
      this.total = parseInt(val) + parseInt(this.totalPriceSelect);
    },
    /**
     * Watch change value totalPriceSelect
     *
     */
    totalPriceSelect: function (val) {
      this.delegateResetEffectShowResult();
      this.total = parseInt(val) + parseInt(this.totalPriceOption);
    },

    /**
     * Watch change value competitiveSiteReviews
     *
     */
    competitiveSiteReviews: function (val) {
      let numberRank = parseInt(val);
      if (Number.isFinite(numberRank)) {
        if (numberRank > 100) {
          this.competitiveSiteReviews = 100;
          return;
        }
        if (numberRank <= 0) {
          this.competitiveSiteReviews = 0;
          return;
        }
      } else {
        return 0;
      }
    },

    isActiveSeoCompetitive: function (val) {
      if (val && this.isActiveSeo) {
        this.isLoadding = false;
        this.setColorProssetSeo();
        this.websiteSeoScore = this.websiteSeoScoreApiResult;
        this.competitiveWebsiteSeoScore =
          this.competitiveWebsiteSeoScoreApiResult;
        this.seoDeviant = this.calculationRating();
        this.$refs.seoDeviant.play();
      }
    },

    isActiveSeo: function (val) {
      if (val && this.isActiveSeoCompetitive) {
        this.isLoadding = false;
        this.setColorProssetSeo();
        this.websiteSeoScore = this.websiteSeoScoreApiResult;
        this.competitiveWebsiteSeoScore =
          this.competitiveWebsiteSeoScoreApiResult;
        this.seoDeviant = this.calculationRating();
        this.$refs.seoDeviant.play();
      }
    },
  },
  methods: {
    /**
     * Export PDF
     *
     */
    async downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },

    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.44,
              pdf.internal.pageSize.getHeight() - 0.3
            );
          }
        })
        .save();
    },

    /**
     * Website seo score ranking
     *
     * @return score ranking
     */
    getSeoScoreRankingWebsite() {
      this.isLoadding = true;
      this.isActiveSeo = false;
      this.axios
        .get(AXIOS_API_SEO.Api, {
          params: {
            category: "seo",
            url: this.urlSeo,
            key: AXIOS_API_SEO.Key,
            fields: "lighthouseResult.categories.seo.score",
          },
          timeout: 35000,
        })
        .then((res) => {
          const score = res.data.lighthouseResult.categories.seo.score;
          this.websiteSeoScoreApiResult = score * 100;
          this.isActiveSeo = true;
        })
        .catch(() => {
          this.websiteSeoScoreApiResult = 0;
          this.isActiveSeo = true;
        });
    },

    setColorProssetSeo() {
      if (this.competitiveWebsiteSeoScoreApiResult >= 80) {
        this.colorProgressCompetitive = "#2eb85c";
        this.colorFillProgressCompetitive = "#32fd7d61";
      } else if (this.competitiveWebsiteSeoScoreApiResult >= 50) {
        this.colorProgressCompetitive = "#fd7e14";
        this.colorFillProgressCompetitive = "#ffc1077d";
      } else {
        this.colorProgressCompetitive = "#e60000";
        this.colorFillProgressCompetitive = "#dc35457a";
      }
      if (this.websiteSeoScoreApiResult >= 80) {
        this.colorProgress = "#2eb85c";
        this.colorFillProgress = "#32fd7d61";
      } else if (this.websiteSeoScoreApiResult >= 50) {
        this.colorProgress = "#fd7e14";
        this.colorFillProgress = "#ffc1077d";
      } else {
        this.colorProgress = "#e60000";
        this.colorFillProgress = "#dc35457a";
      }
    },

    /**
     * Competitive website seo score ranking
     *
     * @return score ranking
     */
    getSeoScoreRankingWebsiteCompetitive() {
      this.isLoadding = true;
      this.isActiveSeoCompetitive = false;
      this.axios
        .get(AXIOS_API_SEO.Api, {
          params: {
            category: "seo",
            url: this.urlSeoCompetitive,
            key: AXIOS_API_SEO.Key,
            fields: "lighthouseResult.categories.seo.score",
          },
          timeout: 35000,
        })
        .then((res) => {
          const score = res.data.lighthouseResult.categories.seo.score;
          this.competitiveWebsiteSeoScoreApiResult = score * 100;
          this.isActiveSeoCompetitive = true;
        })
        .catch(() => {
          this.competitiveWebsiteSeoScoreApiResult = 0;
          this.isActiveSeoCompetitive = true;
        });
    },

    /**
     */
    submitSeo() {
      this.getSeoScoreRankingWebsite();
      this.getSeoScoreRankingWebsiteCompetitive();
    },

    /**
     * get data  export PDF
     *
     */
    getDataExport() {
      let nameQuestion = "";
      let nameAnswer = "";
      this.dataExport = [];

      // select
      this.dataInput.forEach((value, index) => {
        if (value != null && value != "") {
          // get name question
          let question = this.LIST_QUESTION.find((que) => que.id == index);
          if (question != undefined) {
            // check type != input
            if (question.id != 3) {
              nameQuestion = question;

              // get name answer
              let answer = question.answer.find((ans) => ans.value == value);
              if (answer != undefined) {
                nameAnswer = answer;
                let price = this.listPrice.find(
                  (ans) => ans.questionId == question.id
                );
                if (price == undefined) {
                  price = 0;
                } else {
                  price = price.price;
                }
                let recordExportPdf = {
                  nameQue: nameQuestion.name,
                  nameAns: nameAnswer.name,
                  price: price,
                  type: "select",
                };
                this.dataExport.push(recordExportPdf);
              }
            } else if (question.id == 3) {
              let price = this.listPrice.find(
                (ans) => ans.questionId == question.id
              );
              if (price == undefined) {
                price = 0;
              } else {
                price = price.price;
              }
              let recordExportPdf = {
                nameQue: question.name,
                nameAns: value,
                price: price,
                type: "select",
              };
              this.dataExport.push(recordExportPdf);
            }
          }
        }
      });

      // option
      this.isActive.forEach((value, index) => {
        if (value != null && value != "") {
          let option = this.PRICE_OPTIONS.find((ops) => ops.id == index);
          if (option != undefined) {
            let price = this.listPriceOption[option.id];
            if (price == undefined) {
              price = 0;
            }
            let objNameOptions = {
              nameQue: "オプション",
              nameAns: option.name,
              price: price,
              type: "option",
            };
            this.dataExport.push(objNameOptions);
          }
        }
      });
    },

    /**
     * Total price by option
     *
     */
    calculateSelectOption() {
      // declare
      let total = 0;
      this.totalPriceCRV = 0;
      let keywordNumber = 0;
      let numberOfAttractionAreas = 0;
      this.listPrice = [];

      // Calculate the sum for formula type 1
      this.dataInput.forEach((value, index) => {
        if (value != null) {
          // Get info calculation
          let unitPrice = this.UNIT_PRICE_LIST.find(
            (unitPrice) => unitPrice.questionId == index
          );
          if (unitPrice != undefined) {
            // Check type calculation
            if (unitPrice.questionId === 1 || unitPrice.questionId === 3) {
              total += unitPrice.price * value;
              // Save price
              this.listPrice.push({
                questionId: unitPrice.questionId,
                price: unitPrice.price * value,
              });
            } else if (unitPrice.questionId === 6) {
              if (value != "") {
                total += value;
                this.listPrice.push({
                  questionId: unitPrice.questionId,
                  price: value,
                });
              }
            } else if (unitPrice.questionId === 4) {
              if (value != "") {
                numberOfAttractionAreas = parseInt(value);
              }
            } else if (unitPrice.questionId === 2) {
              if (value <= 0) {
                total += 0;
                keywordNumber = 0;
                // Save price
                this.listPrice.push({
                  questionId: unitPrice.questionId,
                  price: 0,
                });
              } else {
                if (value <= 15) {
                  total += value * 50000;
                  // Save price
                  this.listPrice.push({
                    questionId: unitPrice.questionId,
                    price: value * 50000,
                  });
                } else {
                  total += value * 40000;
                  // Save price
                  this.listPrice.push({
                    questionId: unitPrice.questionId,
                    price: value * 40000,
                  });
                }

                keywordNumber = parseInt(value);
              }
            } else if (unitPrice.questionId === 5) {
              if (value == "") {
                value = 0;
              }
              this.totalPriceCRV = value;
              // Save price
              this.listPrice.push({
                questionId: unitPrice.questionId,
                price: value,
              });
            }
          }
        }
      });

      total += numberOfAttractionAreas * keywordNumber;
      // Save price
      this.listPrice.push({
        questionId: 4,
        price: numberOfAttractionAreas * keywordNumber,
      });
      // Set data
      this.totalPriceSelect = total;
    },
    /**
     * Total price by option
     *
     */
    calculateOptionSystem() {
      let total = 0;
      this.listPriceOption = [];
      this.isActive.forEach((value, index) => {
        if (value) {
          let unitPrice = this.PRICE_OPTIONS.find(
            (unitPrice) => unitPrice.id == index
          );
          if (unitPrice != undefined) {
            total += unitPrice.price;
            // Save price
            this.listPriceOption[unitPrice.id] = unitPrice.price;
          }
        }
      });
      this.totalPriceOption = total;
    },
    /**
     * Total price crv
     *
     * @return [ Number ]
     */
    calAddTotalCRV() {
      return (
        parseInt(this.totalPriceSelect) +
        parseInt(this.totalPriceCRV) +
        parseInt(this.totalPriceOption)
      );
    },
    /**
     * Calculated price min
     *
     * @return [ Number ]
     */
    calMinPrice(price) {
      if (parseInt(price) - 120000 <= 0) {
        return 0;
      }
      return parseInt(price) - 120000;
    },
    /**
     * Calculated price Max
     *
     * @return [ Number ]
     */
    calMaxPrice(maxPrice) {
      return parseInt(maxPrice) + 260000;
    },

    /**
     * Calculated ranting
     *
     * @return [ Number ]
     */
    calculationRating() {
      let calculationRatingSite =
        parseInt(this.competitiveWebsiteSeoScore) -
        parseInt(this.websiteSeoScore);
      if (Number.isFinite(parseInt(calculationRatingSite))) {
        this.dataInput[3] = calculationRatingSite + 1;
        return (
          parseInt(this.competitiveWebsiteSeoScore) -
          parseInt(this.websiteSeoScore)
        );
      } else {
        return 0;
      }
    },

    /**
     * calculate Seo Score
     *
     * @return [ Number ]
     */
    calculateSeoScore() {
      if (Number.isFinite(parseInt(this.siteRating))) {
        return parseInt(this.siteRating);
      } else {
        return 0;
      }
    },
  },
  created() {
    // Set data default
    this.dataInput = ["", "", "", "", "", "", ""];
    // Wait for scrolling to stop.
    if (window.innerWidth <= 576) {
      this.sizeBoxProgress = 100;
    } else {
      this.sizeBoxProgress = 130;
    }

    // get today
    var today = new Date();
    this.todayDate =
      today.getFullYear() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getDate();

    // random invoice
    this.randomInvoice = Math.floor(Math.random() * 1010);
  },
  computed: {
    /**
     * list compare
     *
     * @return [ Array ] get list compare
     */
    LIST_COMPARE() {
      return LIST_COMPARE;
    },

    /**
     * Call API get list question
     *
     * @return [ Array ] get list question
     */
    LIST_QUESTION() {
      return LIST_QUESTION?.sort(function (questCurrent, questNext) {
        return questCurrent.order - questNext.order;
      });
    },

    /**
     * TYPE_INPUT
     */
    TYPE_INPUT() {
      return TYPE_INPUT;
    },

    /**
     * UNIT_PRICE
     */
    UNIT_PRICE_LIST() {
      return UNIT_PRICE;
    },

    /**
     * PRICE_OPTIONS
     */
    PRICE_OPTIONS() {
      return PRICE_OPTIONS;
    },
  },
  mounted() {
    // Get dom element
    const boxResultCompetitor = document.querySelector(
      "#box-result-competitor"
    );
    const boxResultMine = document.querySelector("#box-result-mine");
    const boxResultCompetitorBot = document.querySelector(
      "#box-result-competitor-bot"
    );
    const boxResultCompanyBig = document.querySelector(
      "#box-result-company-big"
    );
    const btnShowResult = document.querySelector("#btn-show-result");
    const btnExportPdf = document.querySelector("#btn-export-pdf");
    const waperTotal = document.querySelector("#waper-total");
    boxResultCompetitor.style.opacity = 0;
    this.delegateShowResult = () => {
      this.calculateOptionSystem();
      this.calculateSelectOption();
      this.getDataExport();

      waperTotal.scrollIntoView({
        behavior: "smooth",
      });
      if (
        boxResultCompetitor != null &&
        boxResultMine != null &&
        boxResultCompetitorBot != null &&
        boxResultCompanyBig != null
      ) {
        // boxResultCompetitor.style.opacity = 1;
        boxResultCompetitor.animate(
          [
            { transform: "translateX(-200%)", opacity: 0 },
            { transform: "translateX(0)", opacity: 1 },
          ],
          {
            duration: 900,
            delay: 300,
            // iterations: Infinity,
            direction: "alternate",
          }
        );
        boxResultMine.animate(
          [
            { transform: "translateX(200%)", opacity: 0 },
            { transform: "translateX(0)", opacity: 1 },
          ],
          {
            duration: 900,
            delay: 300,
            iterations: 1,
          }
        );

        boxResultCompetitorBot.animate(
          [
            { transform: "translateX(-200%)", opacity: 0 },
            { transform: "translateX(0)", opacity: 1 },
          ],
          {
            duration: 900,
            delay: 300,
            // iterations: Infinity,
            direction: "alternate",
          }
        );
        boxResultCompanyBig.animate(
          [
            { transform: "translateX(200%)", opacity: 0 },
            { transform: "translateX(0)", opacity: 1 },
          ],
          {
            duration: 900,
            delay: 300,
            iterations: 1,
          }
        );

        //Call back complete boxResultMine
        Promise.all(
          boxResultCompetitor.getAnimations().map(function (animation) {
            return animation.finished;
          })
        ).then(function () {
          boxResultCompetitor.style.opacity = 1;
        });

        //Call back complete boxResultMine
        Promise.all(
          boxResultMine.getAnimations().map(function (animation) {
            return animation.finished;
          })
        ).then(function () {
          boxResultMine.style.opacity = 1;
          btnExportPdf.style.display = "block";
          btnShowResult.style.display = "none";
        });

        //Call back complete boxResultMine
        Promise.all(
          boxResultCompetitorBot.getAnimations().map(function (animation) {
            return animation.finished;
          })
        ).then(function () {
          boxResultCompetitorBot.style.opacity = 1;
        });

        //Call back complete boxResultMine
        Promise.all(
          boxResultCompanyBig.getAnimations().map(function (animation) {
            return animation.finished;
          })
        ).then(function () {
          boxResultCompanyBig.style.opacity = 1;
          btnExportPdf.style.display = "block";
          btnShowResult.style.display = "none";
        });
      }
    };

    //Reset effect
    this.delegateResetEffectShowResult = () => {
      if (
        boxResultCompetitor != null &&
        boxResultMine != null &&
        boxResultCompetitorBot != null &&
        boxResultCompanyBig != null &&
        btnExportPdf != null
      ) {
        boxResultCompetitor.style.opacity = 0;
        boxResultMine.style.opacity = 0;
        boxResultCompetitorBot.style.opacity = 0;
        boxResultCompanyBig.style.opacity = 0;
        btnExportPdf.style.display = "none";
        btnShowResult.style.display = "block";
      }
    };
  },
};
</script>

<style>
@media (min-width: 991px) {
  .w-lg-30 {
    width: 30%;
  }
}
@media (min-width: 576px) and (max-width: 990px) {
  .w-md-30 {
    width: 30%;
    padding-bottom: 30px !important;
  }
}
@media (min-width: 577px) {
  .w-55 {
    width: 55%;
  }
}
h2.title-page {
  text-align: center;
  margin: 30px 0 10px 0;
  font-size: calc(1.2rem + 0.9vw);
}
.page-compare {
  max-width: 1000px;
}

.page-compare .calculate-score-seo circle.ep-circle--progress {
  stroke-width: 13;
}

@media (max-width: 576px) {
  .page-compare .calculate-score-seo circle.ep-circle--progress {
    stroke-width: 10;
  }
  .icon-box-seo {
    padding: 8px;
    margin-bottom: 30px;
    width: 22px !important;
  }
}
.icon-box-seo {
  margin-bottom: 30px;
  max-width: 40px;
}
.form-question:last-child .form-calculation {
  display: none;
}
.form-question select {
  background-image: url("http://cteck.site/wp-content/themes/twentytwelve/images/angle-down-solid.png");
}

.form-question option {
  background: #fff;
  color: #000;
}
.item-question-input:focus {
  box-shadow: none;
}
.box-icon {
  width: 24px;
}
.lable-sell {
  width: 100px;
}
.box .w-20 {
  width: 20%;
}
@media (min-width: 425px) and (max-width: 575px) {
  .box .w-20 {
    padding: 0;
  }
  .box .box-icon {
    display: none;
  }
}
@media (max-width: 424px) {
  .box .w-20 {
    width: 42%;
    padding: 5px;
  }
  .box button span {
    width: 100%;
  }
  .box .box-icon {
    display: none;
  }
}
.box label {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 0.5s ease;
}
.box label p {
  opacity: 0;
  transition: 0.5s;
}
.box label p:hover,
.box label p:focus,
.box label p:active {
  border-radius: 10px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.329);
}
.box .active {
  border-radius: 10px;
  box-shadow: 0 0 0 0.375rem rgb(225 83 97 / 50%) !important;
}
.box .active p {
  border-radius: 10px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.329);
}
.box label:hover {
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 0 0.375rem rgb(13 110 253 / 25%);
}

.box span {
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 204px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: cover;
  background-position: center;
  border-color: rgba(0, 0, 0, 0);
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}
.box .col-6:nth-child(1) span {
  background-image: url("http://cteck.site/wp-content/themes/twentytwelve/images/box1.png");
}
.box .col-6:nth-child(3) span {
  background-image: url("http://cteck.site/wp-content/themes/twentytwelve/images/box2.png");
}
.box .col-6:nth-child(5) span {
  background-image: url("http://cteck.site/wp-content/themes/twentytwelve/images/payment.png");
}
.box .col-6:nth-child(7) span {
  background-image: url("http://cteck.site/wp-content/themes/twentytwelve/images/service.png");
}
.box .col-6:nth-child(9) span {
  background-image: url("http://cteck.site/wp-content/themes/twentytwelve/images/video.png");
}

.box .col-6:nth-child(11) span {
  background-image: url("http://cteck.site/wp-content/themes/twentytwelve/images/guideline.png");
}

.box .active span {
  border: 0px solid rgba(0, 0, 0, 0.7);
}

.box label:hover span,
.box label:hover span {
  border: 0px solid rgba(0, 0, 0, 0.7);
}

@media (min-width: 768px) and (max-width: 990px) {
  .box label,
  .box span {
    width: 100%;
    height: 180px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .box label,
  .box span {
    width: 100%;
    height: 130px;
  }
}
@media (min-width: 426px) and (max-width: 575px) {
  .box label,
  .box span {
    width: 100%;
    height: 184px;
  }
}
@media (max-width: 425px) {
  .box label,
  .box span {
    width: 100%;
    height: 132px;
  }
}

#box-result-competitor {
  z-index: 9;
}
#box-result-mine {
  opacity: 0;
}
.box-btn-show-result {
  min-height: 50px;
}
.box-btn-show-result button {
  border-radius: 24px;
}

.box-payment {
  border-top: 3px solid #2eb85c;
  border-radius: 5px;
  box-shadow: 0px 10px 40px -10px #a3a5ae;
  padding: 30px;
}
.box-payment label {
  color: #2eb85c;
}
.box-payment h3 {
  color: #4c4e61;
}
.box-payment span {
  color: #a3a5ae;
}
@media (max-width: 991px) {
  .box-payment .text-payment {
    flex-direction: column;
  }
}

.box-result {
  border-top: 3px solid #f9b115;
  border-radius: 5px;
  box-shadow: 0px 10px 40px -10px #a3a5ae;
  padding: 10px;
}

.box-result h3 {
  color: #4c4e61;
}
.box-result span {
  color: #a3a5ae;
}

.loadding-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  opacity: 0.5;
  z-index: 100000;
}
.title-box {
  height: 30px;
}

.template-pdf {
  background: url("http://cteck.site/wp-content/themes/twentytwelve/images/bg-pdf.png");
  background-repeat: no-repeat;
}
.template-pdf .content-pdf {
  min-height: 650px;
}
.template-pdf .content-pdf thead {
  border-bottom: 2px solid #0dcaf0;
}
.template-pdf .content-pdf thead tr th {
  margin: 0;
  padding: 0.75rem;
}
.template-pdf .content-pdf tbody tr {
  border-top: 1px solid #dee2e6;
}
.template-pdf .content-pdf tbody tr td {
  padding: 0.75rem;
  margin: 0;
}
</style>
