/**
 * type answer of question
 */
export const TYPE_ANSWER = {
  selectOption: 1,
  selectMultiple: 2,
  input: 3,
};

/**
 * effect status next question
 */
export const EFFECT_STATUS = [
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
];

/**
 * list question and answer
 */
export const LIST_QUESTION_ANSWER = [
  // 1
  {
    name: "ご希望のサイトの種類は？",
    description: "  ※項目は、「システム構築・TOPページ制作」でございます。",
    title: "システム構築費用",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/1a.png",
    visible: true,
    typeQuestion: 1,
    answers: [
      {
        name: "コーポレート",
        icon: "fas fa-building",
        price: 87000,
        typeAnswer: "",
      },
      {
        name: "ランディングページ",
        icon: "fas fa-laptop-house",
        price: 25000,
        typeAnswer: "",
      },
      {
        name: "サービスサイト",
        icon: "fas fa-map-marker-alt",
        price: 75000,
        typeAnswer: "",
      },
      {
        name: "ポータルサイト",
        icon: "fas fa-chalkboard-teacher",
        price: 186000,
        typeAnswer: "",
      },
      {
        name: "通販（EC）サイト",
        icon: "fas fa-box",
        price: 148000,
        typeAnswer: "",
      },
    ],
  },

  // 2
  {
    name: "ご希望のページ数は？",
    description: "",
    title: "下層ページ制作費用",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/2a.png",
    visible: false,
    typeQuestion: 3,
    answers: [
      {
        name: "数字入力",
        price: 16500,
        typeAnswer: "",
      },
    ],
  },

  // 3
  {
    name: "写真の共有は可能ですか？",
    description:
      " ※プロのカメラマンや有料素材をご依頼したい場合は、枚数や出張費により、別途お見積もりになります。",
    visible: false,
    title: "写真素材",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/3a.png",
    typeQuestion: 1,
    answers: [
      {
        name: "はい",
        icon: "fas fa-check",
        price: 12000,
        typeAnswer: "",
      },
      {
        name: " いいえ　（フリー素材などで結構）",
        nameSub1: "いいえ",
        nameSub2: "(フリー素材などで結構)",
        icon: "fas fa-times",
        price: 36000,
        typeAnswer: "",
      },
      {
        name: "プロのカメラマンを 依頼する",
        icon: "fa-solid fa-video",
        price: "別途見積もり",
        typeAnswer: "",
      },
      {
        name: "有料素材",
        icon: "fa-solid fa-book",
        price: "別途見積もり",
        typeAnswer: "",
      },
    ],
  },

  // 4
  {
    name: "会員登録機能は必要ですか？",
    description: " ※会員限定ページが必要の場合は必要を選んでください。",
    visible: false,
    title: "会員サイト機能",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/4a.png",
    typeQuestion: 1,
    answers: [
      {
        name: "不要",
        icon: "fas fa-times",
        price: 0,
        typeAnswer: "",
      },
      {
        name: "必要",
        icon: "fas fa-check",
        price: 94000,
        typeAnswer: "",
      },
    ],
  },

  // 5
  {
    name: "更新管理はどのようにしますか？",
    description:
      " ※自社でサイト管理や修正作業を行わない場合、「依頼する」をご選択ください。",
    visible: false,
    title: "更新管理費用",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/5a.png",
    typeQuestion: 1,
    answers: [
      {
        name: "弊社に、更新管理を依頼する",
        icon: "fa-solid fa-gear",
        price: 16500,
        typeAnswer: "MONTHLY",
      },
      {
        name: "自社で更新・管理 (簡易的な更新をしたい場合)",
        nameSub1: "自社で更新・管理",
        nameSub2: " (簡易的な更新をしたい場合)",
        icon: "fa-solid fa-elevator",
        price: 67000,
        typeAnswer: "",
      },
      {
        name: "自社で高度な更新・管理をする場合",
        icon: "fa-solid fa-rectangle-list",
        price: "別途見積もり",
        typeAnswer: "",
      },
      {
        name: "自社で更新・管理 (一般的な更新をする場合)",
        nameSub1: "自社で更新・管理",
        nameSub2: "(一般的な更新をする場合)",
        icon: "fa-solid fa-business-time",
        price: 92000,
        typeAnswer: "",
      },
      {
        name: "相談したい",
        icon: "fa-solid fa-head-side-cough",
        price: "別途見積もり",
        typeAnswer: "",
      },
    ],
  },

  // 6
  {
    name: "スマホに対応させますか？（レスポンシブ）",
    description: " ※項目は「モバイルフレンドリー対応」でございます。",
    visible: false,
    title: "レスポンシブ",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/6a.png",
    typeQuestion: 1,
    answers: [
      {
        name: "なし",
        icon: "fas fa-times",
        price: 0,
        typeAnswer: "",
      },
      {
        name: "あり",
        icon: "fas fa-check",
        price: 72000,
        typeAnswer: "",
      },
    ],
  },

  // 7
  {
    name: "ロゴの制作は必要ですか？",
    description: "",
    visible: false,
    title: "ロゴ制作",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/7a.png",
    typeQuestion: 1,
    answers: [
      {
        name: "なし",
        icon: "fas fa-times",
        price: 0,
        typeAnswer: "",
      },
      {
        name: "あり（デザインあり）",
        nameSub1: "あり",
        nameSub2: "（デザインあり）",
        icon: "fas fa-check",
        price: 38000,
        typeAnswer: "",
      },
      {
        name: "あり（デザインなし）※ラフ4パターン",
        nameSub1: "あり",
        nameSub2: "（デザインなし）",
        nameSub3: "※ラフ4パターン",
        icon: "fas fa-check",
        price: 78000,
        typeAnswer: "",
      },
    ],
  },

  // 8
  {
    name: "SEO対策は必要ですか？ ",
    subDescription:
      "※サイトで集客までをご希望の場合、キーワードの数を入力して下さい",
    description: "「対策評価レポート」「市場分析レポート」付き",
    visible: false,
    title: "SEO対策",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/8a.png",
    typeQuestion: 3,
    answers: [
      {
        name: "キーワードの数（0~1000）",
        price: 12000,
        typeAnswer: "",
      },
    ],
  },

  // 9
  {
    name: "メールアドレスはいくつ必要ですか？",
    description: "",
    visible: false,
    title: "メールアドレス",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/9a.png",
    typeQuestion: 3,
    answers: [
      {
        name: "アドレス数",
        price: 1000,
        typeAnswer: "",
      },
    ],
  },

  // 10
  {
    name: "追加機能は必要ですか？",
    description: "※項目は、「オプション」でございます。",
    visible: false,
    title: "システム＆実装",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/10a.png",
    typeQuestion: 2,
    answers: [
      {
        name: "オンライン決済機能",
        icon: "fa-solid fa-money-check-dollar",
        price: 36000,
        typeAnswer: "",
      },
      {
        name: "オンライン診断フォーム",
        icon: "fa-solid fa-rectangle-list",
        price: 38000,
        typeAnswer: "",
      },
      {
        name: "動画　編集・作成",
        icon: "fa-solid fa-chalkboard-user",
        price: "別途見積もり",
        typeAnswer: "",
      },
      {
        name: "アニメーション/動画作成",
        icon: "fa-solid fa-film",
        price: "別途見積もり",
        typeAnswer: "",
      },
      {
        name: "オンライン内覧システム（VR）",
        icon: "fa-solid fa-vr-cardboard",
        price: 36000,
        typeAnswer: "",
      },
      {
        name: "その他 各種システム (見積もりツールなど)",
        icon: "fa-solid fa-calculator",
        price: "別途見積もり",
        typeAnswer: "",
      },
      {
        name: "サポート（保守管理）",
        nameSub1: "サポート",
        nameSub2: "（保守管理）",
        icon: "fa-solid fa-headset",
        price: 5600,
        typeAnswer: "MONTHLY",
      },
      {
        name: "イベント予約機能",
        icon: "fa-solid fa-check-to-slot",
        price: 34000,
        typeAnswer: "",
      },
    ],
  },

  // 11
  {
    name: "SNSを連動させますか？",
    description: " ※更新した際、SNSとホームページが同時に更新されます",
    visible: false,
    title: "SNS連動",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/11a.png",
    typeQuestion: 1,
    answers: [
      {
        name: "なし",
        icon: "fas fa-times",
        price: 0,
        typeAnswer: "",
      },
      {
        name: "あり",
        icon: "fas fa-check",
        price: 34000,
        typeAnswer: "",
      },
    ],
  },

  // 12
  {
    name: "サイトのデザインはお決まりですか？",
    description: "",
    visible: false,
    title: "デザイン費用",
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/12a.png",
    typeQuestion: 1,
    answers: [
      {
        name: "あり(デザインデータ)",
        nameSub1: "あり",
        nameSub2: "(デザインデータ)",

        icon: "fas fa-check",
        price: 0,
        typeAnswer: "",
      },
      {
        name: "あり（手書き・サンプルサイト）",
        nameSub1: "あり",
        nameSub2: "(手書き・サンプルサイト)",
        icon: "fas fa-check",
        price: 30000,
        typeAnswer: "",
      },
      {
        name: "なし",
        icon: "fas fa-times",
        price: 80000,
        typeAnswer: "",
      },
    ],
  },

  // 13
  {
    name: "他のご要望はありますか？",
    description: "",
    visible: false,
    img: "http://cteck.site/wp-content/themes/twentytwelve/images/13a.png",
    title: "作業代行費用",
    typeQuestion: 2,
    answers: [
      {
        name: "ARシステム",
        icon: "fa-solid fa-vr-cardboard",
        price: "別途見積もり",
        typeAnswer: "",
      },
      {
        name: "SNS開設",
        icon: "fa-solid fa-microchip",
        price: 5000,
        typeAnswer: "",
      },
      {
        name: "WEBマーケティング＆レポート",
        icon: "fa-solid fa-bullhorn",
        price: 16500,
        typeAnswer: "MONTHLY",
      },
      {
        name: "アプリ制作",
        icon: "fa-solid fa-industry",
        price: "別途見積もり",
        typeAnswer: "",
      },
      {
        name: "メルマガ配信機能",
        icon: "fa-solid fa-envelope",
        price: 54000,
        typeAnswer: "",
      },
      {
        name: "広告運用代行",
        icon: "fa-solid fa-rectangle-ad",
        price: "別途見積もり",
        typeAnswer: "",
      },
      {
        name: "物販機能",
        icon: "fa-solid fa-cart-shopping",
        price: 62000,
        typeAnswer: "MONTHLY",
      },
      {
        name: "なし",
        icon: "fa-solid fa-times",
        price: 0,
        typeAnswer: "",
      },
      {
        name: "MEO対策（グーグルマイビジネス）",
        nameSub1: "MEO対策",
        nameSub2: "(グーグルマイビジネス)",
        icon: "fa-solid fa-m",
        price: 21000,
        typeAnswer: "",
      },
    ],
  },
];
