import Vue from 'vue'

// plugins
import './plugins/bootstrap'
import './plugins/numeral'
import './plugins/vue-number-animation'
import './plugins/vue-ellipse-progress'
import './plugins/vue-axios'
import './plugins/vue-html2pdf'
import './plugins/fontawesome.js'

import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
