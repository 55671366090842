// API
export const AXIOS_API = {
    BaseApi: "http://127.0.0.1/api/"
};

export const TYPE_INPUT = {
    text: 0,
    selectOption: 1
};


// Api Seo
export const AXIOS_API_SEO = {
    Api: "https://www.googleapis.com/pagespeedonline/v5/runPagespeed",
    Key: "AIzaSyBxQbGOjxp8phlcg4k1fPA4vd3Ed9olNbM"
};