<template>
  <div>
    <h2 class="title-page">費用対効果 試算表</h2>
    <div class="d-flex align-items-center justify-content-center" id="home">
      <div class="form-box">
        <form method="" action="" class="mx-3 mx-lg-0">
          <div class="row p-2">
            <div class="w-md-23 my-2 box-title">
              <div class="d-flex align-items-center h-100 border rounded">
                <label class="form-control border-0 m-0">紙媒体 </label>
              </div>
            </div>
            <div class="w-md-77 home-select">
              <div class="row justify-content-around">
                <div class="box-title w-lg-4 w-md-43 my-2">
                  <select
                    class="form-select border-info text-center bg-info text-white"
                    v-model="PaperMedia.PaperMedia"
                  >
                    <option value="0">反応率</option>
                    <option value="1">1名</option>
                    <option value="3">~3名</option>
                    <option value="5">~5名</option>
                    <option value="10">~10名</option>
                    <option value="20">~20名</option>
                    <option value="30">~30名</option>
                  </select>
                </div>
                <div class="box-title w-md-26 w-lg-23 my-2">
                  <input
                    type="text"
                    value="0"
                    v-model="PaperMedia.PaperMediaInput"
                    class="form-control border-warning text-white text-end bg-warning"
                    placeholder="広告費用(数字入力)"
                  />
                </div>
                <div
                  class="d-flex justify-content-between box-title w-md-31 w-lg-34 my-2"
                >
                  <div
                    class="d-flex justify-content-end justify-content-md-between align-items-center form-control bg-success p-0 w-100"
                  >
                    <number
                      ref="PaperMediaData"
                      :from="0"
                      :to="PaperMedia.PaperMediaData"
                      :duration="1"
                      easing="Power1.easeOut"
                      :format="theFormat"
                      class="fs-5 text-end text-white fw-bold me-1"
                    />
                    <span
                      class="bg-secondary border-secondary rounded-end text-white w-md-80p text-end h-100"
                      >回/円</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="w-md-23 my-2 box-title">
              <div class="d-flex align-items-center h-100 border rounded">
                <label class="form-control border-0 m-0">WEB広告 </label>
              </div>
            </div>
            <div class="w-md-77 home-select">
              <div class="row justify-content-around">
                <div class="box-title w-lg-4 w-md-43 my-2">
                  <select
                    class="form-select border-info text-center bg-info text-white"
                    v-model="WebAds.WebAds"
                  >
                    <option value="0">検索ボリューム</option>
                    <option value="1000">検索ボリューム ~1000</option>
                    <option value="1500">検索ボリューム ~1500</option>
                    <option value="2000">検索ボリューム ~2000</option>
                    <option value="3000">検索ボリューム ~3000</option>
                    <option value="5000">検索ボリューム ~5000</option>
                    <option value="7000">検索ボリューム ~7000</option>
                    <option value="10000">検索ボリューム ~10000</option>
                  </select>
                </div>
                <div class="box-title w-md-26 w-lg-23 my-2">
                  <input
                    type="text"
                    class="form-control border-warning text-white text-end bg-warning"
                    v-model="WebAds.WebAdsInput"
                    placeholder="クリック単価"
                  />
                </div>
                <div
                  class="d-flex justify-content-between box-title w-md-31 w-lg-34 my-2"
                >
                  <div
                    class="d-flex justify-content-end justify-content-md-between align-items-center form-control bg-success p-0 w-100"
                  >
                    <number
                      ref="WebAdsData"
                      :from="0"
                      :to="WebAds.WebAdsData"
                      :duration="1"
                      :delay="0.5"
                      easing="Power1.easeOut"
                      :format="theFormat"
                      class="fs-5 text-end text-white fw-bold me-1"
                    />
                    <span
                      class="bg-secondary border-secondary rounded-end text-white w-md-80p text-end h-100"
                      >円/月額</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="w-md-23 my-2 box-title">
              <div class="d-flex align-items-center h-100 border rounded">
                <label class="form-control border-0 m-0">MEO </label>
              </div>
            </div>
            <div class="w-md-77 home-select">
              <div class="row justify-content-around">
                <div class="box-title w-lg-4 w-md-43 my-2">
                  <select
                    class="form-select border-info text-center bg-info text-white"
                    v-model="Meo.Meo"
                  >
                    <option value="3">3KW</option>
                    <option value="6">6KW</option>
                  </select>
                </div>
                <div class="box-title w-md-26 w-lg-23 my-2">
                  <input
                    type="text"
                    class="form-control border-warning text-white text-end bg-warning"
                    v-model="Meo.MeoInput"
                    placeholder="月額"
                  />
                </div>
                <div
                  class="d-flex justify-content-between box-title w-md-31 w-lg-34 my-2"
                >
                  <div
                    class="d-flex justify-content-end justify-content-md-between align-items-center form-control bg-success p-0 w-100"
                  >
                    <number
                      ref="MeoData"
                      :from="0"
                      :to="Meo.MeoData"
                      :duration="1"
                      easing="Power1.easeOut"
                      :format="theFormat"
                      class="fs-5 text-end text-white fw-bold me-1"
                    />
                    <span
                      class="bg-secondary border-secondary rounded-end text-white w-md-80p text-end h-100"
                      >円/月額</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="w-md-23 my-2 box-title">
              <div class="d-flex align-items-center h-100 border rounded">
                <label class="form-control border-0 m-0">自然検索 </label>
              </div>
            </div>
            <div class="w-md-77 home-select">
              <div class="row justify-content-around">
                <div class="box-title w-lg-4 w-md-43 my-2">
                  <select
                    class="form-select border-info text-center bg-info text-white"
                    v-model="NaturalSearch.NaturalSearch"
                  >
                    <option value="3">3KW</option>
                    <option value="5">5KW</option>
                    <option value="100">100KW</option>
                  </select>
                </div>
                <div class="box-title w-md-26 w-lg-23 my-2">
                  <span
                    class="form-control border-warning text-white text-end bg-warning text-end"
                    ><span class="opacity-0">0</span></span
                  >
                </div>
                <div
                  class="d-flex justify-content-between box-title w-md-31 w-lg-34 my-2"
                >
                  <div
                    class="d-flex justify-content-end justify-content-md-between align-items-center form-control bg-success p-0 w-100"
                  >
                    <number
                      ref="NaturalSearchData"
                      :from="0"
                      :to="NaturalSearch.NaturalSearchData"
                      :duration="1"
                      easing="Power1.easeOut"
                      :format="theFormat"
                      class="fs-5 text-end text-white fw-bold me-1"
                    />
                    <span
                      class="bg-secondary border-secondary rounded-end text-white w-md-80p text-end h-100"
                      >円/月額</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="w-md-23 my-2 box-title">
              <div class="d-flex align-items-center h-100 border rounded">
                <label class="form-control border-0 m-0">ポータルサイト </label>
              </div>
            </div>
            <div class="w-md-77 home-select">
              <div class="row justify-content-around">
                <div class="box-title w-lg-4 w-md-43 my-2">
                  <select
                    class="form-select border-info text-center bg-info text-white"
                    v-model="PortalSite.PortalSite"
                  >
                    <option value="1">1サイト</option>
                    <option value="2">2サイト</option>
                    <option value="3">3サイト</option>
                    <option value="4">4サイト</option>
                    <option value="5">5サイト</option>
                  </select>
                </div>
                <div class="box-title w-md-26 w-lg-23 my-2">
                  <input
                    type="text"
                    class="form-control border-warning text-white text-end bg-warning"
                    v-model="PortalSite.PortalSiteInput"
                    placeholder="月額費用（年間契約）"
                  />
                </div>
                <div
                  class="d-flex justify-content-between box-title w-md-31 w-lg-34 my-2"
                >
                  <div
                    class="d-flex justify-content-end justify-content-md-between align-items-center form-control bg-success p-0 w-100"
                  >
                    <number
                      ref="PortalSiteData"
                      :from="0"
                      :to="PortalSite.PortalSiteData"
                      :duration="1"
                      easing="Power1.easeOut"
                      :format="theFormat"
                      class="fs-5 text-end text-white fw-bold me-1"
                    />
                    <span
                      class="bg-secondary border-secondary rounded-end text-white w-md-80p text-end h-100"
                      >年額</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- add  -->
          <div class="row p-2">
            <div class="w-md-23 my-2 box-title">
              <div
                class="d-flex align-items-center bg-white h-100 border rounded"
              >
                <label class="form-control border-0 m-0"
                  >リピート率（新規10名あたり）</label
                >
              </div>
            </div>
            <div class="w-md-77 home-select">
              <div class="row justify-content-around">
                <div class="box-title w-lg-4 w-md-43 my-2">
                  <input
                    type="text"
                    class="form-control border-info text-center bg-info text-white change-color-input"
                    v-model="RepeatRate.RepeatRate"
                    placeholder="紹介のリピート率"
                  />
                </div>
                <div class="box-title w-md-26 w-lg-23 my-2">
                  <input
                    type="text"
                    class="form-control border-warning text-white text-end bg-warning"
                    placeholder="単価"
                    v-model="RepeatRate.RepeatRateInput"
                  />
                </div>
                <div
                  class="d-flex justify-content-between box-title w-md-31 w-lg-34 my-2"
                >
                  <div
                    class="d-flex justify-content-end justify-content-md-between align-items-center form-control bg-success p-0 w-100"
                  >
                    <number
                      ref="RepeatRateData"
                      :from="0"
                      :to="RepeatRate.RepeatRateData"
                      :duration="1"
                      easing="Power1.easeOut"
                      :format="theFormat"
                      class="fs-5 text-end text-white fw-bold me-1"
                    />
                    <span
                      class="bg-secondary border-secondary rounded-end text-white w-md-80p text-end h-100"
                      >円</span
                    >
                  </div>
                </div>
              </div>
              <div class="row justify-content-around">
                <div class="box-title w-lg-4 w-md-43 my-2">
                  <input
                    type="text"
                    class="form-control border-info text-center bg-info text-white change-color-input"
                    v-model="RepeatRate.RepeatRate2"
                    placeholder="口コミのリピート率"
                  />
                </div>
                <div class="box-title w-md-26 w-lg-23 my-2">
                  <input
                    type="text"
                    class="form-control border-warning text-white text-end bg-warning"
                    placeholder="単価"
                    v-model="RepeatRate.RepeatRateInput2"
                  />
                </div>
                <div
                  class="d-flex justify-content-between box-title w-md-31 w-lg-34 my-2"
                >
                  <div
                    class="d-flex justify-content-end justify-content-md-between align-items-center form-control bg-success p-0 w-100"
                  >
                    <number
                      ref="RepeatRateData2"
                      :from="0"
                      :to="RepeatRate.RepeatRateData2"
                      :duration="1"
                      easing="Power1.easeOut"
                      :format="theFormat"
                      class="fs-5 text-end text-white fw-bold me-1"
                    />
                    <span
                      class="bg-secondary border-secondary rounded-end text-white w-md-80p text-end h-100"
                      >円</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="w-md-23 my-2 box-title">
              <div class="d-flex align-items-center h-100 border rounded">
                <label class="form-control border-0 m-0"
                  >新規集客コスト（1人当たり）</label
                >
              </div>
            </div>
            <div class="w-md-77 home-select">
              <div class="row justify-content-around">
                <div class="box-title w-lg-4 w-md-43 my-2">
                  <input
                    type="text"
                    v-model="Statistical.Statistical"
                    placeholder="月間集客数"
                    class="form-control border-info text-center bg-info text-white change-color-input"
                  />
                </div>
                <div class="box-title w-md-26 w-lg-23 my-2">
                  <input
                    type="text"
                    class="form-control border-warning text-white text-end bg-warning"
                    placeholder="新規獲得コスト"
                    v-model="Statistical.StatisticalInput"
                  />
                </div>
                <div
                  class="d-flex justify-content-between box-title w-md-31 w-lg-34 my-2"
                >
                  <div
                    class="d-flex justify-content-end justify-content-md-between align-items-center form-control bg-success p-0 w-100"
                  >
                    <number
                      ref="StatisticalData"
                      :from="0"
                      :to="Statistical.StatisticalData"
                      :duration="1"
                      easing="Power1.easeOut"
                      :format="theFormat"
                      class="fs-5 text-end text-white fw-bold me-1"
                    />
                    <span
                      class="bg-secondary border-secondary rounded-end text-white w-md-80p text-end h-100"
                      >円/月額</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// Library declaration
var numeral = require("numeral");

/**
 * Number format for real number type
 *
 * @return [ Number ]
 */
function formatNumberDecimal(value) {
  return numeral(value).format("0,0");
}

/**
 * Check value , undefined,null
 *
 * @return [ Number ]
 */
function checkInput(value) {
  if (typeof value == "undefined" || value == null || value === "") return 0;
  return value;
}

export default {
  // Data declaration
  data() {
    return {
      // 紙媒体 obj
      PaperMedia: {
        PaperMedia: 0,
        PaperMediaInput: 0,
        PaperMediaData: 0,
      },
      // WEB広告 obj
      WebAds: { WebAds: 0, WebAdsInput: 0, WebAdsData: 0 },
      // MEO obj
      Meo: { Meo: 3, MeoInput: 0, MeoData: 0 },
      // 自然検索
      NaturalSearch: {
        NaturalSearch: 3,
        NaturalSearchInput: 0,
        NaturalSearchData: 0,
      },
      // 自然検索
      PortalSite: { PortalSite: 1, PortalSiteInput: 0, PortalSiteData: 0 },
      // リピート率（新規10名あたり） obj
      RepeatRate: {
        RepeatRate: null,
        RepeatRate2: null,
        RepeatRateInput: null,
        RepeatRateInput2: null,
        RepeatRateData: 0,
        RepeatRateData2: 0,
      },
      // 合計
      Statistical: {
        Statistical: null,
        StatisticalInput: null,
        StatisticalData: 0,
      },
    };
  },
  // Handle change of value
  watch: {
    "PaperMedia.PaperMedia": function (val) {
      this.PaperMediaFunction(val, this.PaperMedia.PaperMediaInput);
    },
    "PaperMedia.PaperMediaInput": function (val) {
      this.PaperMediaFunction(this.PaperMedia.PaperMedia, val);
    },
    "WebAds.WebAds": function (val) {
      this.WebAdsFunction(val, this.WebAds.WebAdsInput);
    },
    "WebAds.WebAdsInput": function (val) {
      this.WebAdsFunction(this.WebAds.WebAds, val);
    },
    "Meo.Meo": function (val) {
      this.MeoFunction(val, this.Meo.MeoInput);
    },
    "Meo.MeoInput": function (val) {
      this.MeoFunction(this.Meo.Meo, val);
    },
    "NaturalSearch.NaturalSearch": function (val) {
      this.NaturalSearchFunction(val);
    },
    "NaturalSearch.NaturalSearchInput": function () {},
    "PortalSite.PortalSite": function (val) {
      this.PortalSiteFunction(val, this.PortalSite.PortalSiteInput);
    },
    "PortalSite.PortalSiteInput": function (val) {
      this.PortalSiteFunction(this.PortalSite.PortalSite, val);
    },
    "RepeatRate.RepeatRate": function (val) {
      this.RepeatRateFunction(val, this.RepeatRate.RepeatRateInput);
    },
    "RepeatRate.RepeatRateInput": function (val) {
      this.RepeatRateFunction(this.RepeatRate.RepeatRate, val);
    },
    "RepeatRate.RepeatRate2": function (val) {
      this.RepeatRateFunction2(val, this.RepeatRate.RepeatRateInput2);
    },
    "RepeatRate.RepeatRateInput2": function (val) {
      this.RepeatRateFunction2(this.RepeatRate.RepeatRate2, val);
    },
    "Statistical.Statistical": function (val) {
      this.StatisticalFunction(val, this.Statistical.StatisticalInput);
    },
    "Statistical.StatisticalInput": function (val) {
      this.StatisticalFunction(this.Statistical.Statistical, val);
    },
  },
  methods: {
    /**
     * Number format
     *
     * @return [ Number ]
     */
    theFormat(number) {
      return formatNumberDecimal(number);
    },

    /**
     * Calculated according to the formula
     *
     */
    PaperMediaFunction: function (selectVal, inputVal) {
      if (Number.isFinite(selectVal / inputVal)) {
        this.PaperMedia.PaperMediaData = (selectVal / inputVal) * 7;
      } else {
        this.PaperMedia.PaperMediaData = 0;
      }
      this.$refs.PaperMediaData.play();
    },
    /**
     * Calculated according to the formula
     *
     */
    WebAdsFunction: function (selectVal = 0, inputVal = 0) {
      inputVal = checkInput(inputVal);
      this.WebAds.WebAdsData = selectVal * inputVal;
      this.$refs.WebAdsData.play();
    },
    /**
     * Calculated according to the formula
     *
     */
    MeoFunction: function (selectVal, inputVal) {
      if (Number.isFinite(selectVal / inputVal)) {
        this.Meo.MeoData = selectVal / inputVal;
      } else {
        this.Meo.MeoData = 0;
      }
      this.$refs.MeoData.play();
    },
    /**
     * Calculated according to the formula
     *
     */
    NaturalSearchFunction: function (selectVal = 0) {
      this.NaturalSearch.NaturalSearchData = selectVal * 150000 + 200000;
      this.$refs.NaturalSearchData.play();
    },
    /**
     * Calculated according to the formula
     *
     */
    PortalSiteFunction: function (selectVal = 0, inputVal = 0) {
      inputVal = checkInput(inputVal);
      this.PortalSite.PortalSiteData = selectVal * inputVal;

      this.$refs.PortalSiteData.play();
    },
    /**
     * Calculated according to the formula
     *
     */
    RepeatRateFunction: function (selectVal, inputVal) {
      this.RepeatRate.RepeatRateData = inputVal * selectVal;
      this.$refs.RepeatRateData.play();
    },
    /**
     * Calculated according to the formula
     *
     */
    RepeatRateFunction2: function (selectVal, inputVal) {
      this.RepeatRate.RepeatRateData2 = inputVal * selectVal;
      this.$refs.RepeatRateData2.play();
    },
    /**
     * Calculated according to the formula
     *
     */
    StatisticalFunction: function (selectVal, inputVal) {
      if (Number.isFinite(inputVal / selectVal)) {
        this.Statistical.StatisticalData = inputVal / selectVal;
      } else {
        this.Statistical.StatisticalData = 0;
      }
      this.$refs.StatisticalData.play();
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
.w-md-80p {
  width: 80px;
}
@media (min-width: 768px) {
  .w-md-77 {
    width: 77%;
  }
  .w-md-43 {
    width: 43%;
  }
  .w-md-31 {
    width: 31%;
  }
  .w-md-26 {
    width: 26%;
  }
  .w-md-25 {
    width: 25%;
  }
  .w-md-23 {
    width: 23%;
  }
}
@media (min-width: 992px) {
  .w-lg-4 {
    width: 40%;
  }
  .w-lg-34 {
    width: 34%;
  }
  .w-lg-23 {
    width: 23%;
  }
}

#home {
  min-height: calc(100vh - 140px);
}

.form-box {
  max-width: 1000px;
}
.home-select .form-select {
  background-image: url("http://cteck.site/wp-content/themes/twentytwelve/images/angle-down-solid.png");
}

@media (min-width: 992px) {
  .w-md-80p {
    padding: 0.35rem 0.75rem 0 0;
  }
  .home-select .fs-5 {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0 0 0.75rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .w-md-80p {
    width: 80px;
    padding: 0.35rem 0.35rem 0 0.35rem;
  }
  .form-box .box-title {
    padding: 0 5px;
  }
  .home-select .fs-5 {
    width: 110px;
    font-size: 16px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0 0 0.75rem;
  }
}
@media (max-width: 767px) {
  .form-box .box-title {
    padding: 0 3px;
  }
  .w-md-80p {
    height: 38px !important;
    padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  }
  .home-select .fs-5 {
    padding: 0 0.75rem 0 0.75rem;
    margin: 0 !important;
  }
}
.change-color-input::placeholder {
  color: #fff;
  opacity: 0.7;
}
</style>
