<template>
  <layout-default>
    <service-table />
  </layout-default>
</template>

<script>
// Layout
import LayoutDefault from "@/layouts/default.vue";

import ServiceTable from "@/modules/service/components/ServiceTable.vue";

export default {
  name: "Service",
  components: {
    LayoutDefault,
    ServiceTable,
  },
};
</script>