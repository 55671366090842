<template>
  <layout-default>
    <home-question />
  </layout-default>
</template>

<script>
// Layout
import LayoutDefault from "@/layouts/default.vue";

import HomeQuestion from "@/modules/home/components/HomeQuestion.vue";

export default {
  name: "Home",
  components: {
    LayoutDefault,
    HomeQuestion,
  },
};
</script>