<template>
  <div>
    <div v-if="!isSubmit">
      <h2 class="title-page">ホームページ</h2>
      <div
        class="container page-home"
        v-for="(item, index) in questionAnswerList"
        :key="index"
      >
        <div v-if="questionAnswerList[index].visible">
          <div class="page-navigation text-end">
            <span class="px-3 text-white bg-info" id="count-page"
              ><span class="fw-bold fs-5 text-yellow">{{ index + 1 }}</span>
              <span class="fw-bold fs-5 text-white">
                / {{ questionAnswerList.length }}</span
              ></span
            >
          </div>
          <div
            class="d-flex justify-content-center flex-column align-items-center my-4 my-sm-5"
          >
            <div>
              <img
                :src="item.img"
                alt=""
                height="160"
                style="max-width: 216px; object-fit: contain;"
                class="d-inline-block align-text-top p-2"
              />
            </div>
            <h2 :class="{ active: effectSatus[index].status }" class="zoomIn mt-2">
              {{ item.name }}
            </h2>
            <p
              :class="{ active: effectSatus[index].status }"
              class="zoomIn mb-1"
            >
              {{ item.description }}
            </p>
            <p class="mb-1">
              {{ item.subDescription ? item.subDescription : "" }}
            </p>
            <select-home-page
              :class="{ active: effectSatus[index].status }"
              class="fadeIn my-4 my-md-5 w-100"
              :question="item"
              :active="item.typeQuestion === TYPE_ANSWER.selectOption"
              v-if="item.typeQuestion === TYPE_ANSWER.selectOption"
              @on-next="onNextQuestion(index)"
              @on-submit="onSubmit($event, index)"
            />
            <input-home-page
              :class="{ active: effectSatus[index].status }"
              class="fadeIn my-5 w-100 pt-4 pb-3"
              :question="item"
              :is-contact="index == 7 || index == 1"
              v-if="item.typeQuestion === TYPE_ANSWER.input"
              @on-next="onNextQuestion(index)"
              @on-submit="onSubmit($event, index)"
            />
            <multi-home-page
              :class="{ active: effectSatus[index].status }"
              class="fadeIn my-4 my-md-5 w-100 card-multi"
              :question="item"
              :active="item.typeQuestion === TYPE_ANSWER.selectMultiple"
              v-if="item.typeQuestion === TYPE_ANSWER.selectMultiple"
              @on-next="onNextQuestion(index)"
              @on-submit="onSubmit($event, index)"
            />
            <span>
              <button v-if="index === 0" class="d-none" type="button">
                前へ戻る
              </button>
              <button
                v-if="index !== 0"
                class="btn-homepage btn btn-lg btn-outline-info py-2 px-sm-5 px-1 me-2"
                type="button"
                @click="onBackQuestion(index)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-reply"
                  class="m-0 me-sm-2"
                />
                前へ戻る
              </button>
              <button
                v-if="
                  index === 9 ||
                  index !== 12 ||
                  item.typeQuestion === TYPE_ANSWER.input
                "
                class="btn-homepage btn btn-lg btn-info py-2 px-sm-5 px-1 text-white"
                type="button"
                @click="onNextQuestion(index)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-circle-right"
                  class="m-0 me-sm-2"
                />
                後へ進む
              </button>
              <button
                v-if="index === 12"
                class="btn-homepage btn btn-lg btn-info py-2 px-sm-5 px-1 text-white"
                type="button"
                @click="onNextQuestion(index)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-circle-right"
                  class="m-0 me-sm-2"
                />
                結果を見る
              </button>
            </span>
          </div>
        </div>
      </div>
      <section class="calc">
        <h4>概算見積もり結果</h4>
        <div class="price_area fw-bold">
          <number
            ref="totalData"
            :from="0"
            :to="total"
            :duration="1"
            easing="Power1.easeOut"
            :format="theFormat"
            class="result fs-3 text-danger me-1"
          />
          <span>円～</span>
        </div>
      </section>
    </div>
    <div v-else>
      <box-result-home-page
        @on-back="onBackQuestion(100)"
        :items="models.filter((item) => item != null || item != undefined)"
      />
    </div>
  </div>
</template>

<script>
// Library declaration
var numeral = require("numeral");

/**
 * Number format for real number type
 *
 * @return [ Number ]
 */
function formatNumberDecimal(value) {
  return numeral(value).format("0,0");
}

import SelectHomePage from "@/modules/homePage/components/SelectHomePage.vue";
import InputHomePage from "@/modules/homePage/components/InputHomePage.vue";
import MultiHomePage from "@/modules/homePage/components/MultiHomePage.vue";
import BoxResultHomePage from "@/modules/homePage/components/BoxResultHomePage.vue";

import {
  LIST_QUESTION_ANSWER,
  TYPE_ANSWER,
  EFFECT_STATUS,
} from "@/modules/homePage/constants.js";

export default {
  components: {
    SelectHomePage,
    InputHomePage,
    MultiHomePage,
    BoxResultHomePage,
  },
  data() {
    return {
      questionAnswerList: LIST_QUESTION_ANSWER,
      effectSatus: EFFECT_STATUS,
      isSubmit: false,
      models: [],
      total: 0,
      visible: false,
    };
  },
  created() {
    this.goToFirst();
  },
  methods: {
    /**
     * Number format
     *
     * @return [ Number ]
     */
    theFormat(number) {
      return formatNumberDecimal(number);
    },
    /**
     * Move on to the next question
     *
     * @param {Number} index Numbers of examples
     * @public This is a public method
     */
    onNextQuestion(index) {
      let countQuestion = this.questionAnswerList.length;
      index = parseInt(index);
      let indexNext = index + 1;
      // Check is the last element
      if (countQuestion > indexNext) {
        this.questionAnswerList[index].visible = false;
        this.questionAnswerList[indexNext].visible = true;
        setTimeout(() => {
          this.effectSatus[indexNext].status = true;
          this.effectSatus[index].status = false;
        }, 100);
      } else {
        this.isSubmit = true;
      }
    },

    /**
     * Move on to the back question
     *
     * @param {Number} index Numbers of examples
     * @public This is a public method
     */
    onBackQuestion(indexAns) {
      let countQuestion = this.questionAnswerList.length;
      indexAns = parseInt(indexAns);
      let indexBack = indexAns - 1;
      // Check is the first element
      if (countQuestion > indexBack && indexBack >= 0) {
        this.questionAnswerList[indexAns].visible = false;
        this.questionAnswerList[indexBack].visible = true;
        setTimeout(() => {
          this.effectSatus[indexBack].status = true;
          this.effectSatus[indexAns].status = false;
        }, 100);
      } else {
        this.isSubmit = false;
        this.questionAnswerList[countQuestion - 1].visible = true;
      }
    },

    /**
     * Go to first question
     *
     * @public This is a public method
     */
    goToFirst() {
      this.total = 0;
      let countQuestion = this.questionAnswerList.length;
      this.questionAnswerList.forEach((question) => {
        question.visible = false;
      });
      if (countQuestion > 0) {
        this.questionAnswerList[0].visible = true;
      }
      this.effectSatus.forEach((effectSatus) => {
        effectSatus.status = false;
      });
      if (this.effectSatus.length > 0) {
        setTimeout(() => {
          this.effectSatus[0].status = true;
        }, 100);
      }
    },

    onSubmit(submit, index) {
      this.models[index] = submit;
      this.total = 0;
      for (let i = 0; i < this.models.length; i++) {
        const element = this.models[i];
        if (element != undefined) {
          if (element.type == 2) {
            element.answers.forEach((answer) => {
              if (parseInt(answer.price)) {
                if (answer.typeAnswer !== "MONTHLY") {
                  this.total += answer.price;
                  this.$refs.totalData.play();
                }
              }
            });
          } else {
            if (parseInt(element.price)) {
              this.total += element.price;
              this.$refs.totalData.play();
            }
          }
        }
      }
    },
  },

  computed: {
    TYPE_ANSWER() {
      return TYPE_ANSWER;
    },
  },
};
</script>
<style scoped>
.fadeIn {
  transition: all 0.5s ease-in-out;
  opacity: 0.2;
}
.fadeIn.active {
  opacity: 1;
}
.zoomIn {
  transition: all 0.5s ease-in-out;
  transform: scale(0.5);
  opacity: 0.2;
}
.zoomIn.active {
  opacity: 1;
  transform: scale(1);
}
.text-yellow {
  color: #ffeb3b;
}
.btn-homepage {
  border-radius: 28px;
}
.btn-homepage:hover {
  color: #fff;
}
#count-page {
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 28px;
}

.calc {
  width: 295px;
  position: fixed;
  z-index: 99999;
  bottom: 20px;
  right: 20px;
  display: block;
  padding: 10px 40px;
  background: #f7f7f7;
  border: 1px solid #ddd;
  text-align: center;
  box-shadow: 0 10px 40px -10px #a3a5ae;
}
@media (max-width: 675px) {
  .calc {
    bottom: 0px;
    right: 0px;
    border: 0px;
    border-top: 1px solid #ddd;
    width: 100%;
    height: 55px;
    padding: 10px 0px;
  }
  .calc h4 {
    position: absolute;
    top: 18px;
    left: 60px;
    font-size: 15px;
  }
  .calc .price_area {
    position: absolute;
    top: 7px;
    left: 190px;
  }
}
@media (min-width: 1201px) {
  .card-multi {
    width: 60% !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .card-multi {
    width: 69% !important;
  }
}
</style>
