<template>
  <layout-default>
    <index />
  </layout-default>
</template>

<script>
// Layout
import LayoutDefault from "@/layouts/default.vue";

import Index from "@/modules/homePage/components/Index.vue";

export default {
  name: "HomePage",
  components: {
    LayoutDefault,
    Index,
  },
};
</script>
