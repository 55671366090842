<template>
  <header
    class="d-flex justify-content-lg-between align-items-center text-center"
  >
    <a class="logo" href="https://dreamcode.jp/" target="blank">
      <img
        src="http://cteck.site/wp-content/themes/twentytwelve/images/logo.png"
        alt=""
        height="74"
        style="max-width: 245px"
        class="d-inline-block align-text-top p-2"
      />
    </a>
    <!-- <h2 class="m-0 d-none d-lg-block">{{ currentRouteName }}</h2> -->
    <nav class="menu d-inline-block">
      <ul class="m-0">
        <li class="d-inline-block position-relative text-center">
          <a href="/landingpage" title="TOP">TOP</a>
        </li>
        <li class="d-inline-block position-relative text-center">
          <a href="/#/homePage" title="ホームページ">ホームページ</a>
        </li>
        <li class="d-inline-block position-relative text-center">
          <a href="/#/" title="費用対効果">費用対効果</a>
        </li>
        <li class="d-inline-block position-relative text-center">
          <a href="/#/home" title="見積もり">WEB集客</a>
        </li>
        <li class="d-inline-block position-relative text-center">
          <a href="/#/service" title="シミュレーション">シミュレーション</a>
        </li>
      </ul>
    </nav>
    <nav class="nav1 d-none">
      <div class="hidden_box">
        <input type="checkbox" id="label1" />
        <label for="label1" onclick="" class="r-btn bg-primary"
          ><span></span
        ></label>
        <div class="hidden_show bg-primary">
          <ul class="nav1-a">
            <li>
              <a href="/#/homePage" title="ホームページ">ホームページ</a>
            </li>
            <li>
              <a href="/#/" title="費用対効果">費用対効果</a>
            </li>
            <li>
              <a href="/#/home" title="見積もり">WEB集客</a>
            </li>
            <li>
              <a href="/#/service" title="シミュレーション">シミュレーション</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "HeaderCompoment",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
<style scoped>
header {
  border-top: solid 20px #204894;
  border-bottom: 1px solid #e9ecef;
  height: 94px;
  box-sizing: border-box;
}
.menu ul li a {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 13px;
  color: #525252;
  letter-spacing: 1px;
  font-size: 19px;
  text-decoration: none;
  font-family: "Noto Serif JP", serif;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.menu ul li a:hover {
  color: #e60000;
}
@media (max-width: 991px) {
  .menu {
    display: none !important;
  }
  .nav1 {
    display: block !important;
  }
  .nav1 #label1 {
    display: none;
  }
  .nav1 #label1:checked ~ .r-btn {
    background-color: #fff !important;
  }
  .nav1 #label1:checked ~ .r-btn span {
    background-color: #fff0;
  }
  .nav1 #label1:checked ~ .r-btn span::before {
    top: 0px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #321fdb;
  }
  .nav1 #label1:checked ~ .r-btn span::after {
    bottom: 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #321fdb;
  }
  .nav1 .r-btn {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 90;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 45px;
    width: 45px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .nav1 #label1 ~ .r-btn span,
  .nav1 #label1 ~ .r-btn span::before,
  .nav1 #label1 ~ .r-btn span:after {
    content: "";
    display: block;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background-color: #ffffff;
    position: absolute;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
  }
  .nav1 #label1 ~ .r-btn span::before {
    top: 8px;
  }
  .nav1 #label1 ~ .r-btn span::after {
    bottom: 8px;
  }
  .hidden_box .hidden_show {
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: 0.8s;
    -o-transition: 0.8s;
    transition: 0.8s;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    z-index: 89;
  }
  .hidden_box input:checked ~ .hidden_show {
    padding: 20px 25px;
    height: auto;
    opacity: 1;
    margin: 0 auto;
    left: 0;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 5px 8px 0px rgb(0 0 0 / 73%);
    box-shadow: 0px 5px 8px 0px rgb(0 0 0 / 73%);
    max-height: 100%;
    overflow: scroll;
  }
  .hidden_box input:checked ~ .hidden_show ul.nav1-a {
    opacity: 1;
    height: auto;
  }
  .hidden_show .nav1-a {
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    width: calc(100% - 70px);
  }
  .hidden_show .nav1-a li {
    text-align: left;
    margin-bottom: 10px;
    border-bottom: 0.5px solid #fff;
    padding: 0 5px 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .hidden_show .nav1-a li a {
    display: inline-block;
    padding-right: 15px;
    margin-bottom: 5px;
    color: #fff;
    text-decoration: none;
  }
  .hidden_show .nav1-a li a span {
    font-family: "Noto Serif JP", serif;
    font-weight: bold;
    font-size: 20px;
    padding-right: 5px;
    color: #fff;
  }
}
</style>
