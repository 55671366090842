<template>
  <div class="container">
    <h2 class="title-page">効果測定シュミレーション</h2>
    <h3 class="pt-4 m-0">貴社サイトの現状と市場データ</h3>
    <div class="row pt-2 pb-3 pb-lg-4">
      <div class="col-lg-7">
        <div class="d-md-flex border rounded-3">
          <div class="w-md-72">
            <table class="service-table h-100 w-100">
              <thead>
                <tr class="bg-warning text-white">
                  <th class="w-50">キーワード</th>
                  <th class="">現在の順位</th>
                  <th class="">月間検索数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in dataTable" :key="item.id">
                  <td><input v-model="item.keyword" /></td>
                  <td>
                    <input class="text-end" v-model="item.rank" />
                  </td>
                  <td>
                    <input class="text-end" v-model="item.search" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-md-28">
            <table class="service-table h-100 w-100">
              <thead>
                <tr class="bg-warning text-white">
                  <th>現在のアクセス数</th>
                </tr>
              </thead>
              <tbody
                class="d-md-flex justify-content-md-center align-items-md-center h-100"
              >
                <tr class="bg-white">
                  <td>
                    <input
                      class="fs-4 fw-bold text-dark text-end"
                      v-model="currentAccessNumberOf"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- web -->
      <div class="d-none d-lg-block col-lg-1">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="arrow-wave d-flex">
            <span class="position-relative"></span>
            <span class="position-relative"></span>
            <span class="position-relative"></span>
          </div>
        </div>
      </div>

      <!-- mobile and ipad -->
      <div class="d-lg-none col-lg-1 py-3">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="arrow-wave-mobile">
            <span class="position-relative d-block"></span>
            <span class="position-relative d-block"></span>
            <span class="position-relative d-block"></span>
          </div>
        </div>
      </div>
      <!-- ~~~~~~~~~~~ -->
      <div class="col-lg-4">
        <div class="card mb-4 text-white bg-success h-100 rounded-3">
          <div
            class="card-body pb-0 d-flex justify-content-evenly flex-column align-items-center"
          >
            <div>
              <div class="fs-4 text-center text-white">集客対象合計</div>
              <div class="d-flex align-items-center">
                <number
                  ref="PaperMediaData"
                  :from="0"
                  :to="totalTargetCustomers"
                  :duration="1"
                  :format="theFormat"
                  easing="Power1.easeOut"
                  class="fs-1 fw-bold me-2 m-0 text-yellow"
                />
                <span class="fs-4 fw-normal text-yellow"
                  >(<number
                    ref="PaperMediaData"
                    :from="0"
                    :to="divisionExcel"
                    :duration="1"
                    :format="theFormatDecimal"
                    easing="Power1.easeOut"
                    class="fs-4 fw-normal text-yellow"
                  />
                  倍
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="long-arrow-alt-up"
                    class="svg-inline--fa fa-long-arrow-alt-up fa-w-8 mb-1"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 256 512"
                    width="20"
                    height="20"
                    color="red"
                  >
                    <path
                      fill="currentColor"
                      d="M88 166.059V468c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12V166.059h46.059c21.382 0 32.09-25.851 16.971-40.971l-86.059-86.059c-9.373-9.373-24.569-9.373-33.941 0l-86.059 86.059c-15.119 15.119-4.411 40.971 16.971 40.971H88z"
                    ></path></svg
                  >)</span
                >
              </div>
            </div>
            <div class="fst-italic text-white">"全てのワードを10位以内にすれば"</div>
          </div>
        </div>
      </div>
    </div>

    <!-- -------------------- -->
    <h3 class="pt-2 pt-lg-4 m-0">
      月間検索数はGoogleによるデータとなります（Yahoo!、Microsoftは除く）
    </h3>
    <div class="row">
      <div class="col-lg-4 pt-2 pb-lg-3">
        <div
          class="box-service d-flex flex-column bg-white text-black text-left h-100"
        >
          <h3 class="m-0 mb-4 fw-bold text-center">順位別クリック率</h3>
          <img src="http://cteck.site/wp-content/themes/twentytwelve/images/bando.png" alt="" class="img-fluid w-100" />
          <a
            class="text-decoration-none text-center fst-italic mt-2 mt-lg-2"
            style="font-size: 11px"
            href="https://backlinko.com/google-ctr-stats"
            target="blank"
            ><svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="location-arrow"
              class="svg-inline--fa fa-location-arrow fa-w-16 me-1 mb-1"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="11px"
              height="11px"
            >
              <path
                fill="currentColor"
                d="M444.52 3.52L28.74 195.42c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 51.17 70.36 67.17 92.75 19.19l191.9-415.78c15.99-38.39-25.59-79.97-63.97-63.97z"
              ></path></svg
            >出典：BACKLINKO （英語）</a
          >
        </div>
      </div>
      <div class="d-lg-none pt-3">
        <div
          class="box-service-link d-flex flex-column justify-content-between p-3 h-100"
        >
          <div>
            <p>1ページ目と2メージ目でこんなに違うクリック率</p>
            <p>上位3位でクリック率60%以上</p>
            <p>2ページ目以降はクリック率1%以下</p>
          </div>
          <div class="text-center">
            <a
              href="https://blog.kairosmarketing.net/inbound/conversion-average-140320/#i-2"
              class="btn btn-info fw-bold text-decoration-none text-white w-100 h-100"
              target="blank"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="hand-point-right"
                class="svg-inline--fa fa-hand-point-right fa-w-16 me-2"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="20px"
                height="20px"
                color="white"
              >
                <path
                  fill="currentColor"
                  d="M512 199.652c0 23.625-20.65 43.826-44.8 43.826h-99.851c16.34 17.048 18.346 49.766-6.299 70.944 14.288 22.829 2.147 53.017-16.45 62.315C353.574 425.878 322.654 448 272 448c-2.746 0-13.276-.203-16-.195-61.971.168-76.894-31.065-123.731-38.315C120.596 407.683 112 397.599 112 385.786V214.261l.002-.001c.011-18.366 10.607-35.889 28.464-43.845 28.886-12.994 95.413-49.038 107.534-77.323 7.797-18.194 21.384-29.084 40-29.092 34.222-.014 57.752 35.098 44.119 66.908-3.583 8.359-8.312 16.67-14.153 24.918H467.2c23.45 0 44.8 20.543 44.8 43.826zM96 200v192c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V200c0-13.255 10.745-24 24-24h48c13.255 0 24 10.745 24 24zM68 368c0-11.046-8.954-20-20-20s-20 8.954-20 20 8.954 20 20 20 20-8.954 20-20z"
                ></path></svg
              >コンバージョン率の目安と平均</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="row h-100">
          <div class="col-12 col-sm-7 pt-3 pt-lg-2 pb-0 pb-sm-3">
            <div class="box-service bg-white text-black text-left h-100">
              <h3 class="m-0 mb-4 fw-bold text-center">集客目標</h3>
              <p class="d-flex justify-content-between">
                <label class="title">
                  <span>現状月間のセッション数 : </span>
                </label>
                <label>
                  <number
                    ref="PaperMediaData"
                    :from="0"
                    :to="currentNumberOfMonthly"
                    :duration="1"
                    :format="theFormat"
                    easing="Power1.easeOut"
                    class="fw-bold text-warning"
                  />
                </label>
              </p>

              <p class="d-flex justify-content-between">
                <label class="title">
                  <span>上記のワードでの流入 :</span>
                </label>
                <label>
                  <span class="price fw-bold">なし</span>
                </label>
              </p>

              <p class="d-flex justify-content-between">
                <label class="title">
                  <span>自然検索第1位のクリック率 :</span>
                </label>
                <label>
                  <span class="fw-bold text-danger">31.73</span>
                  %
                </label>
              </p>

              <p class="d-flex justify-content-between">
                <label class="title">
                  <span>自然検索第5位のクリック率 :</span>
                </label>
                <label>
                  <span class="fw-bold text-danger">9.51</span>
                  %
                </label>
              </p>

              <p class="d-flex justify-content-between">
                <label class="title">
                  <span>自然検索第10位のクリック率 :</span>
                </label>
                <label>
                  <span class="fw-bold text-danger">3.09</span>
                  %
                </label>
              </p>

              <p class="d-flex justify-content-between">
                <label class="title">
                  <span>仮に上記ワードが10位になった場合</span>
                </label>
              </p>
              <p class="d-flex justify-content-between mb-0">
                <label>
                  <number
                    ref="PaperMediaData"
                    :from="0"
                    :to="totalTargetCustomers"
                    :duration="1"
                    :format="theFormat"
                    easing="Power1.easeOut"
                    class="fw-bold text-danger"
                  />
                </label>
                <label>
                  <span class="fw-bold text-warning">x</span>
                </label>
                <label>
                  <span class="price fw-bold text-danger">3.09%</span>
                </label>
                <label>
                  <span class="fw-bold text-success">=</span>
                </label>
                <label>
                  <number
                    ref="PaperMediaData"
                    :from="0"
                    :to="productExcel"
                    :duration="1"
                    :format="theFormat"
                    easing="Power1.easeOut"
                    class="fw-bold text-danger"
                  />
                  <span class="text-warning">クリック</span>
                </label>
              </p>
            </div>
          </div>
          <div class="col-sm-5 pt-3 pt-lg-2 pb-3">
            <div class="box-service bg-white text-black text-left h-100">
              <h3 class="m-0 mb-4 fw-bold text-center">CV目標</h3>
              <p>
                <label class="title">
                  <span>目標セッション数</span>
                </label>
              </p>

              <p class="text-center">
                <label class="w-100">
                  <input
                    class="form-control fw-bold text-warning conversion-goal text-end"
                    type="text"
                    v-model="accessTarget"
                  />
                </label>
              </p>

              <p
                class="d-flex justify-content-between"
                v-for="item in cvrs"
                :key="item.id"
              >
                <label class="title">
                  <span>{{ item.title }}</span>
                </label>
                <label class="d-flex align-items-center">
                  <number
                    ref="WebAdsData"
                    :from="0"
                    :to="item.value"
                    :duration="1"
                    :format="theFormatDecimal"
                    easing="Power1.easeOut"
                    class="fw-bold text-danger"
                  />
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="users"
                    class="svg-inline--fa fa-users fa-w-20 ms-1"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    widht="13px"
                    height="13px"
                  >
                    <path
                      fill="currentColor"
                      d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
                    ></path>
                  </svg>
                </label>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- -------------------- -->
    <div class="row pt-0 pt-lg-2 pb-3">
      <div class="col-lg-4 pb-3 pb-lg-0 d-none d-lg-block">
        <div
          class="box-service-link d-flex flex-column justify-content-between p-3 h-100"
        >
          <div>
            <p>1ページ目と2メージ目でこんなに違うクリック率</p>
            <p>上位3位でクリック率60%以上</p>
            <p>2ページ目以降はクリック率1%以下</p>
          </div>
          <div class="text-center">
            <a
              href="https://blog.kairosmarketing.net/inbound/conversion-average-140320/#i-2"
              class="btn btn-info fw-bold text-decoration-none text-white w-100 h-100"
              target="blank"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="hand-point-right"
                class="svg-inline--fa fa-hand-point-right fa-w-16 me-2"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="20px"
                height="20px"
                color="white"
              >
                <path
                  fill="currentColor"
                  d="M512 199.652c0 23.625-20.65 43.826-44.8 43.826h-99.851c16.34 17.048 18.346 49.766-6.299 70.944 14.288 22.829 2.147 53.017-16.45 62.315C353.574 425.878 322.654 448 272 448c-2.746 0-13.276-.203-16-.195-61.971.168-76.894-31.065-123.731-38.315C120.596 407.683 112 397.599 112 385.786V214.261l.002-.001c.011-18.366 10.607-35.889 28.464-43.845 28.886-12.994 95.413-49.038 107.534-77.323 7.797-18.194 21.384-29.084 40-29.092 34.222-.014 57.752 35.098 44.119 66.908-3.583 8.359-8.312 16.67-14.153 24.918H467.2c23.45 0 44.8 20.543 44.8 43.826zM96 200v192c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V200c0-13.255 10.745-24 24-24h48c13.255 0 24 10.745 24 24zM68 368c0-11.046-8.954-20-20-20s-20 8.954-20 20 8.954 20 20 20 20-8.954 20-20z"
                ></path></svg
              >コンバージョン率の目安と平均</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div
          class="box-service-link d-flex flex-column justify-content-between p-3 h-100"
        >
          <div>
            <p>
              ※業界別コンバージョン率の平均の調査（出典：MarketingSherpa[英語]）
            </p>
            <p>ECサイト1～3％、サービス業・医療4～8％、ＢtoB10％</p>
            <p>
              ※この資料はあくまでも仮定での数値であり、集客数UPを保証するもの
              ではございません。導入に関してはご検討の上、ご判断くださいませ。
            </p>
          </div>
          <div class="text-center">
            <a
              href="http://www.s-bokan.com/blog/seo/access-analysis/difference-access-count-ranking.html"
              class="btn btn-info fw-bold text-decoration-none text-white"
              target="blank"
              ><svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="hand-point-right"
                class="svg-inline--fa fa-hand-point-right fa-w-16 me-2"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="20px"
                height="20px"
                color="white"
              >
                <path
                  fill="currentColor"
                  d="M512 199.652c0 23.625-20.65 43.826-44.8 43.826h-99.851c16.34 17.048 18.346 49.766-6.299 70.944 14.288 22.829 2.147 53.017-16.45 62.315C353.574 425.878 322.654 448 272 448c-2.746 0-13.276-.203-16-.195-61.971.168-76.894-31.065-123.731-38.315C120.596 407.683 112 397.599 112 385.786V214.261l.002-.001c.011-18.366 10.607-35.889 28.464-43.845 28.886-12.994 95.413-49.038 107.534-77.323 7.797-18.194 21.384-29.084 40-29.092 34.222-.014 57.752 35.098 44.119 66.908-3.583 8.359-8.312 16.67-14.153 24.918H467.2c23.45 0 44.8 20.543 44.8 43.826zM96 200v192c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V200c0-13.255 10.745-24 24-24h48c13.255 0 24 10.745 24 24zM68 368c0-11.046-8.954-20-20-20s-20 8.954-20 20 8.954 20 20 20 20-8.954 20-20z"
                ></path></svg
              >掲載順位によって大きく変わるアクセス数</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Library declaration
var numeral = require("numeral");

/**
 * Number format for integer number type
 *
 * @return [ Number ]
 */
function formatNumber(value) {
  return numeral(value).format("0,0");
}

/**
 * Number format for real number type
 *
 * @return [ Number ]
 */
function formatNumberDecimal(value) {
  return numeral(value).format("0,0.00");
}

export default {
  // Data declaration
  data() {
    return {
      // Data defalut
      dataTable: [
        {
          id: 1,
          keyword: "",
          rank: "",
          search: "",
        },
        {
          id: 2,
          keyword: "",
          rank: "",
          search: "",
        },
        {
          id: 3,
          keyword: "",
          rank: "",
          search: "",
        },
        {
          id: 4,
          keyword: "",
          rank: "",
          search: "",
        },
      ],
      // cvrs data
      cvrs: [
        {
          id: 1,
          title: "CV率1％",
          ratio: 0.01,
          value: 0,
        },
        {
          id: 2,
          title: "CV率3％",
          ratio: 0.03,
          value: 0,
        },
        {
          id: 3,
          title: "CV率8％",
          ratio: 0.08,
          value: 0,
        },
        {
          id: 4,
          title: "CV率10％",
          ratio: 0.1,
          value: 0,
        },
        {
          id: 5,
          title: "CV率15％",
          ratio: 0.15,
          value: 0,
        },
      ],
      totalTargetCustomers: 800,
      currentAccessNumberOf: 0,
      accessTarget: 0,
      colLg: "col-lg-1",
    };
  },
  methods: {
    /**
     * Number format for real number type
     *
     * @return [ Number ]
     */
    sumSearches(data = []) {
      this.totalTargetCustomers = 0;
      data.forEach((value) => {
        if (Number.isInteger(parseInt(value.search))) {
          this.totalTargetCustomers += parseInt(value.search);
        }
      });
    },
    calCRVCustomer(accessTarget = 0) {
      for (let index = 0; index < this.cvrs.length; index++) {
        this.cvrs[index].value = this.cvrs[index].ratio * accessTarget;
      }
    },

    /**
     * Number format
     *
     * @return [ Number ]
     */
    theFormat(number) {
      return formatNumber(number);
    },
    /**
     * Number format
     *
     * @return [ Number ]
     */
    theFormatDecimal(number) {
      return formatNumberDecimal(number);
    },
  },
  computed: {
    /**
     * Calc Multiplication
     *
     * @return [ Number ]
     */
    productExcel() {
      return parseInt(this.totalTargetCustomers) * 0.0309;
    },

    /**
     * Calc Division
     *
     * @return [ Number ]
     */
    divisionExcel() {
      // convert Type currentAccessNumberOf = String
      const currentAccessNumber = this.currentAccessNumberOf.toString();
      if (
        Number.isFinite(
          parseInt(this.totalTargetCustomers) /
            currentAccessNumber.replaceAll(",", "")
        )
      ) {
        return (
          parseInt(this.totalTargetCustomers) /
          currentAccessNumber.replaceAll(",", "")
        );
      } else {
        return 0;
      }
    },

    /**
     * Current number of monthly sessions
     *
     * @return [ Number ]
     */
    currentNumberOfMonthly() {
      // convert Type currentAccessNumberOf = String
      const currentAccessNumber = this.currentAccessNumberOf.toString();
      // format currentNumberOfMonthly = 0,000
      const currentNumberOfMonthly = currentAccessNumber.replaceAll(",", "");
      if (Number.isInteger(parseInt(currentNumberOfMonthly))) {
        return parseInt(currentNumberOfMonthly);
      } else {
        return 0;
      }
    },
  },
  created() {
    this.sumSearches(this.dataTable);
  },
  watch: {
    /**
     * Watch change value dataTable
     *
     */
    dataTable: {
      handler: function (val) {
        this.sumSearches(val);
      },
      deep: true,
    },

    /**
     * Watch change value currentAccessNumberOf
     *
     */
    currentAccessNumberOf: {
      handler: function (val) {
        // convert Type val = String
        const valStr = val.toString();
        // format currentAccessNumberOf = 0,000
        this.currentAccessNumberOf = formatNumber(valStr);
      },
      deep: true,
    },

    /**
     * Watch change value accessTarget
     *
     */
    accessTarget: {
      handler: function (val) {
        // convert Type val = String
        const valStr = val.toString();
        // format accessTarget = 0,000
        this.accessTarget = formatNumber(valStr);
        // remove whitespace valStr
        const rmAccessTarget = this.accessTarget.replaceAll(",", "");
        if (Number.isInteger(parseInt(rmAccessTarget))) {
          this.calCRVCustomer(parseInt(rmAccessTarget));
        } else {
          this.calCRVCustomer(0);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.arrow-wave {
  cursor: pointer;
  font-size: 0;
}

.arrow-wave span {
  height: 20px;
  width: 15px;
  opacity: 0.5;
}

.arrow-wave span::before,
.arrow-wave span::after {
  display: block;
  content: "";
  position: absolute;
  height: 5px;
  width: 20px;
  background-color: aquamarine;
}

.arrow-wave span::before {
  top: 0px;
  right: 0px;
  transform-origin: right center;
  transform: rotate(45deg);
}

.arrow-wave span::after {
  top: -3px;
  right: 0;
  transform-origin: right center;
  transform: rotate(-45deg);
}
.arrow-wave span:nth-child(1n) {
  animation: animate-arrow-wave 1.2s infinite;
  animation-delay: 0.25s;
}
.arrow-wave span:nth-child(2n) {
  animation: animate-arrow-wave 1.2s infinite;
  animation-delay: 0.5s;
}

.arrow-wave span:nth-child(3n) {
  animation: animate-arrow-wave 1.2s infinite;
  animation-delay: 0.75s;
}

.arrow-wave-mobile {
  cursor: pointer;
  font-size: 0;
}

.arrow-wave-mobile span {
  height: 14px;
  width: 20px;
  opacity: 0.5;
}

.arrow-wave-mobile span::before,
.arrow-wave-mobile span::after {
  display: block;
  content: "";
  position: absolute;
  height: 5px;
  width: 20px;
  background-color: aquamarine;
}

.arrow-wave-mobile span::before {
  top: -3px;
  left: -3px;
  transform-origin: left center;
  transform: rotate(45deg);
}

.arrow-wave-mobile span::after {
  top: -3px;
  right: -3px;
  transform-origin: right center;
  transform: rotate(-45deg);
}

.arrow-wave-mobile span:nth-child(1n) {
  animation: animate-arrow-wave 1.2s infinite;
  animation-delay: 0.25s;
}
.arrow-wave-mobile span:nth-child(2n) {
  animation: animate-arrow-wave 1.2s infinite;
  animation-delay: 0.5s;
}

.arrow-wave-mobile span:nth-child(3n) {
  animation: animate-arrow-wave 1.2s infinite;
  animation-delay: 0.75s;
}

@keyframes animate-arrow-wave {
  0% {
    opacity: 0.3;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.3;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.text-yellow {
  color: #ffeb3b;
}
@media (min-width: 768px) {
  .w-md-72 {
    width: 72%;
  }
  .w-md-28 {
    width: 28%;
  }
}
.service-table thead tr th {
  padding: 0.5rem;
}
.service-table tbody tr {
  -webkit-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
}
.service-table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.service-table tbody tr td {
  padding: 0.5rem;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 0;
}
.service-table tbody tr td input,
.conversion-goal {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.service-table tbody tr td input:focus {
  border-bottom: 1px solid #26a69a;
  -webkit-box-shadow: 0 1px 0 0 #26a69a;
  box-shadow: 0 1px 0 0 #26a69a;
}
.dc-table tbody {
  border-top: none;
}
.dc-table tbody tr:last-child td {
  border-bottom-width: inherit;
}
.box-service {
  border-top: 3px solid #2eb85c;
  border-radius: 5px;
  box-shadow: 0px 10px 40px -10px #a3a5ae;
  padding: 30px 15px;
}

.box-service label {
  color: #a3a5ae;
}

.box-service h3 {
  color: #4c4e61;
}
.box-service span {
  color: #a3a5ae;
}

.box-service-link {
  border-top: 3px solid #0dcaf0;
  border-radius: 5px;
  box-shadow: 0px 10px 40px -10px #a3a5ae;
}
</style>
