<template>
  <footer>
    <span class="text-white">© 2021 Dream Code, Inc</span>
  </footer>
</template>

<script>
export default {
  name: "FooterCompoment",
};
</script>
<style scoped>
footer {
  background: #e60000;
  padding: 5px 0;
  text-align: center;
  font-family: "Noto Serif JP", serif;
}
</style>
