<template>
  <div class="LayoutDefault">
    <header-compoment />
    <main class="LayoutDefault__main">
      <slot />
    </main>
    <footer-compoment />
  </div>
</template>

<script>
import HeaderCompoment from "@/components/HeaderCompoment.vue";
import FooterCompoment from "@/components/FooterCompoment.vue";

export default {
  components: {
    HeaderCompoment,
    FooterCompoment,
  },
};
</script>

<style scoped>
.LayoutDefault__main {
  min-height: calc(100vh - 130px);
}
.LayoutDefault {
  font-family: "Noto Sans JP", "游ゴシック体", "Yu Gothic", YuGothic,
    "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo,
    "MS Pゴシック", "MS PGothic", sans-serif;
}
</style>
