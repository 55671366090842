<template>
  <div>
    <ul class="row p-0 justify-content-center m-0 mb-sm-2">
      <li
        v-for="(answer, indexAns) in question.answers"
        class="col-6 col-sm-4 col-md-3 col-lg-2 box-answer mt-1"
        :class="[
          { effect_fade_in: active },
          'delay-effect-fade-in-' + (indexAns + 1),
        ]"
        :key="indexAns"
      >
        <label @click="onNextQuestion(answer, question)"
          ><p class="m-0">
            <font-awesome-icon :icon="answer.icon" size="2x" />
            <span
              >{{ answer.nameSub1 ? answer.nameSub1 : answer.name }} <br /><span
                class="m-0"
                >{{ answer.nameSub2 ? answer.nameSub2 : "" }}<br /><span
                  class="m-0"
                  >{{ answer.nameSub3 ? answer.nameSub3 : "" }}</span
                ></span
              ></span
            >
          </p></label
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataSelect: {
        question: "",
        title: "",
        answer: "",
        price: "",
        type: "",
        typeAnswer: "",
      },
    };
  },
  props: ["question", "active"],
  methods: {
    /**
     * Move on to the next question
     */
    onNextQuestion(answer, question) {
      const submit = this.dataSelect;
      submit.question = question.name;
      submit.title = question.title;
      submit.answer = answer.name;
      submit.price = answer.price;
      submit.type = question.typeQuestion;
      submit.typeAnswer = answer.typeAnswer;
      submit.orderBy = 1;
      this.$emit("on-next", true);
      this.$emit("on-submit", submit);
    },
  },
};
</script>

<style scoped>
.w-12 {
  width: 12%;
}
.page-home ul li {
  list-style: none;
  display: inline-block;
  vertical-align: top;
  padding: 0;
}
@media (min-width: 1200px) {
  .page-home ul li {
    width: auto !important;
  }
}
.page-home ul li label {
  width: 150px;
  height: 145px;
  margin: 5px;
  border-radius: 0.75em;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}
@media (min-width: 576px) and (max-width: 991px) {
  .page-home ul li label {
    width: 165px;
    height: 165px;
  }
}
@media (max-width: 575px) {
  .page-home ul li {
    padding: 6px;
  }
  .page-home ul li label {
    width: 100%;
    height: 150px;
    margin: 0px;
  }
  .page-home ul li label p span {
    height: 70px !important;
  }
}
.page-home ul li:nth-child(5n + 1) label {
  /* background: #0dcaf093; */
  border: 1px solid #0dcaf0;
  border-top: 3px solid #0dcaf0;
  /* box-shadow: 0 0.35em 0 #0dcaf0; */
}
.page-home ul li:nth-child(5n + 2) label {
  /* background: #6e42c1a2; */
  border: 1px solid #6f42c1;
  border-top: 3px solid #6f42c1;
  /* box-shadow: 0 0.35em 0 #6f42c1; */
}
.page-home ul li:nth-child(5n + 3) label {
  /* background: #e55353b9; */
  border: 1px solid #e55353;
  border-top: 3px solid #e55353;
  /* box-shadow: 0 0.35em 0 #e55353; */
}
.page-home ul li:nth-child(5n + 4) label {
  /* background: #2eb85cb6; */
  border: 1px solid #2eb85c;
  border-top: 3px solid #2eb85c;
  /* box-shadow: 0 0.35em 0 #2eb85c; */
}
.page-home ul li:nth-child(5n + 5) label {
  /* background: #f9b115b6; */
  border: 1px solid #f9b115;
  border-top: 3px solid #f9b115;
  /* box-shadow: 0 0.35em 0 #f9b115; */
}

.page-home ul li:nth-child(5n + 1) label p svg {
  color: #0dcaf0;
}
.page-home ul li:nth-child(5n + 2) label p svg {
  color: #6f42c1;
}
.page-home ul li:nth-child(5n + 3) label p svg {
  color: #e55353;
}
.page-home ul li:nth-child(5n + 4) label p svg {
  color: #2eb85c;
}
.page-home ul li:nth-child(5n + 5) label p svg {
  color: #f9b115;
}
.page-home ul li label:hover {
  -webkit-animation: flash 0.2s;
  animation: flash 0.2s;
}
.page-home ul li:nth-child(5n + 1) label:hover {
  border: 2px solid #0dcaf0;
}
.page-home ul li:nth-child(5n + 2) label:hover {
  border: 2px solid #6f42c1;
}
.page-home ul li:nth-child(5n + 3) label:hover {
  border: 2px solid #e55353;
}
.page-home ul li:nth-child(5n + 4) label:hover {
  border: 2px solid #2eb85c;
}
.page-home ul li:nth-child(5n + 5) label:hover {
  border: 2px solid #f9b115;
}
.page-home ul li label p {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 8px 15px;
  height: 145px;
  text-align: center;
  justify-content: space-between;
}
.page-home ul li label p span {
  height: 60px;
}
</style>
