import Vue from "vue";
const numeral = require("numeral");
/*
Format price in JP
*/
Vue.filter("formatPriceJP", function (value) {
  return numeral(value).format("0,0");
});

Vue.filter("formatPriceDecimalJP", function (value) {
  return numeral(value).format("0,0.0");
});
